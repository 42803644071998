import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import { fetchHelper } from '../../helpers/fetchHelper'

export default class Hero extends Component {
  constructor (props) {
    super(props)
  }

  state = {
    inboundPrice: 0,
    outboundPrice: 0,
    leftHover: true
  }

  handleFetchPrices () {
    const api = 'api/Purchase/GetCheapestPackagePrice/'

    fetchHelper.getJson(api)
      .then(res => {
        this.setState({ outboundPrice: res.data.outbound, inboundPrice: res.data.inbound })
      }).catch(err => console.error(err))
  }

  componentDidMount () {
    this.handleFetchPrices()
  }

  scrollTo () {
    scroller.scrollTo('start', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  handleSignup () {
    this.props.history.push('/get-started')
  }

  render () {
    return (
			<div className="full-width full-hero p-0 no-overlay new-hero hero-video-demo bg-light pb-0" id="hero">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 pb-0 pt-xs-30">
							<div className="content text-center">
								<h1 className="text-700 text-darker">Virtual Phone Numbers &amp; <br className="d-none d-md-block" />Business VoIP Phone Systems</h1>

                <h2 className="h4 text-400 mb-30 mt-30 text-dark-light">Your complete business-class cloud-based phone system & virtual phone number, <br className="d-none d-md-block" />
                  featuring <Link to={'/features'} className="underline text-primary" id="Over20PowerFeaturesLink">over 40 free features</Link> that competitors typically charge for.
                </h2>

								<div className="row">
									<div className="col-md-12 text-center mt-30">
										<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-20 mb-xs-20 mr-xxs-0" onClick={() => this.handleSignup()} id="GetStartedButton1">Choose your plan</Button>
                    <a href="https://get.switchboardfree.co.uk/voip-quote" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-20">Get a no-obligation quote</a>
									</div>
								</div>

								<div className="row justify-content-center">
									<div className="col-md-10 col-xs-12 pt-60">
										<img src={`/images/headers/new-hero-v${this.props.version}.jpg`} alt="SwitchboardFREE VoIP Phone Systems" className="img-responsive" style={{ maxWidth: '1200px' }} />
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
    )
  }
}
