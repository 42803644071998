import moment from 'moment'
import React, { useEffect } from 'react'
import {
	Button, FloatingLabel, Form,
	Modal, OverlayTrigger,
	Popover
} from 'react-bootstrap'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import AddressLookup from '../../components/AddressLookup'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import {
	MultiBuyDiscount,
	MultiBuyDiscountMin, VoipHandsetDiscount
} from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import {
	type AddressDto
} from '../../models/GenericTypes'
import {
	type Basket, type Hardware,
	type PurchaseResponseDto, type ReduxState
} from '../../models/SignupTypes'
import { getFirstName, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryCountyChange, handleDeliveryNameChange, handleDeliveryNoteChange, handleDeliveryPcodeChange, handleDeliveryTownChange, handleNotificationChange } from '../../store/SBF'
import LoadingNotification from '../LoadingNotification'
import { calculateVat } from '../../helpers/utils'

interface PurchasePhoneProps {
  currentBasket: Basket
  discount: number
  handlePurchasePhones?: (purchasePowerSupply: boolean) => void
  handleClosePowerUpsell?: () => void
  hideName?: boolean
  handleDeliveryNameChange?: (obj: any) => void | undefined
  handleDeliveryAdd1Change?: (obj: any) => void | undefined
  handleDeliveryAdd2Change?: (obj: any) => void | undefined
  handleDeliveryTownChange?: (obj: any) => void | undefined
  handleDeliveryCountyChange?: (obj: any) => void | undefined
  handleDeliveryPcodeChange?: (obj: any) => void | undefined
  handleDeliveryNoteChange?: (obj: any) => void | undefined
  handleSave?: () => void
  loggedIn?: boolean
  handleCloseDevicesModal: () => void
  handleUpdateHardware: (hardware: Hardware[] | undefined) => void
  showEditDevices: boolean
  hideFree?: boolean
  clientId?: number | undefined
  buttonText?: string | undefined
  hideButton?: boolean
  isSignup?: boolean
  displayInline?: boolean | undefined
  notify: (obj: any) => void
  delivery: AddressDto
  userDetails?: any | undefined
  displayInstalments?: boolean | undefined
  returnRoute?: string | undefined
  purchaseUrl: string
  notSlider?: boolean | undefined
  CustomDivClass?: string | undefined
  hideAccessories?: boolean
}

interface PurchasePhoneState {
  isLoading: boolean
  products?: Hardware[]
  hardwareQty: number
  purchasePowerSupply: boolean
  showPurchasePhoneCheck: boolean
  showSuccess: boolean
  gotAddress: boolean
  gettingAddress: boolean
  showCheckout: boolean
  total: number
  inProgress: boolean
  displayPurchasePhonesModal: boolean
  notes: string
  key: string
  checkoutClosed: boolean
  email: string
  name: string
  showProdInfo: boolean
	modelId: string
	instalments: boolean
	failed: boolean
}

const PurchasePhones: React.FunctionComponent<PurchasePhoneProps> = (
  props: PurchasePhoneProps
): JSX.Element => {
  const basket: Basket = props.currentBasket

  const initialState: PurchasePhoneState = {
    isLoading: true,
    products: undefined,
    hardwareQty: 0,
    showPurchasePhoneCheck: false,
    showSuccess: false,
    gotAddress: false,
    purchasePowerSupply: false,
    gettingAddress: false,
    showCheckout: false,
    total: 0,
    inProgress: false,
    displayPurchasePhonesModal: false,
    notes: '',
    key: '',
    checkoutClosed: false,
    email: '',
    name: '',
    showProdInfo: false,
		modelId: '',
		instalments: false,
		failed: false
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const [state, setState] = React.useState<PurchasePhoneState>(initialState)
  const query = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (query.get('newPhone') && !query.get('paymentFailed')) {
      props.notify({ message: 'Your phones have been ordered.' })
    }
  }, [])

  useEffect(() => {
    if (state?.products === undefined || state?.products?.length === 0) {
      setTimeout(() => {
        handleGetHardware()
      }, 2000)
    }
    if (props?.loggedIn === true && !state.gotAddress && !state.gettingAddress) {
      // Check if we have some delivery details
      setState({ ...state, gettingAddress: true })
      fetchHelper.getJson<AddressDto>(`api/Users/GetClientDeliveryAddress/${props.clientId}`)
        .then(res => {
          const data = res.data
          props.handleDeliveryNameChange?.(data.name)
          props.handleDeliveryAdd1Change?.(data.add1)
          props.handleDeliveryAdd2Change?.(data.add2)
          props.handleDeliveryTownChange?.(data.add3)
          props.handleDeliveryCountyChange?.(data.add4)
          props.handleDeliveryPcodeChange?.(data.pcode)

          setState({ ...state, showPurchasePhoneCheck: true, showSuccess: false, gotAddress: true, gettingAddress: false })
        }).catch(err => { console.error(err) })
    }

    if ((state?.products?.length ?? 0 > 0) && query.get('paymentFailed') && !state.showCheckout && !state.checkoutClosed) {
      handleReviewPurchase()
    }

    if ((props.delivery?.pcode === undefined || props.delivery?.pcode === '') && LocalStorageHelper.getItem('DeliveryAddress')?.value !== null) {
      handleUpdateAddress(LocalStorageHelper.getItem('DeliveryAddress').value)
    }
  }, [handleGetHardware])

	function renderProducts() {
    return (
			<>

				{moment().month() === 11 && // December
					<div className="xmas-banner brad-10 p-30 col-12 col-sm-7 mx-auto text-center">
						<div id="snow">
						</div>
						<p className="text-500 mb-20 lead">
							Just a friendly heads-up for the Christmas holidays.
						</p>
						<p>
							If you’re planning to order new handsets, please note that we'll be closed soon for Christmas.<br />
							Any orders placed between December 21st, 2023, and January 2nd, 2024, will be dispatched on January 2nd, as soon as we’re back in the office.
						</p>
					</div>
				}
			{((state.products !== null && state.products !== undefined) && state.products.length > 0) && renderHandsetInfoModal()}

				{(props.notSlider
				  ? <>
					<div className="row products not-slider">
						{renderProductsContent()}
					</div>
				</>
				  :
				// @ts-expect-error
				<Slider className="products " {...settings}>
					{renderProductsContent()}
				</Slider>

				)}

				</>
    )
  }

  function renderProductsContent () {
    return (

      state.products?.map((prod, i) =>
						<div className={`prod-item ${props.CustomDivClass ? props.CustomDivClass : 'col-lg-3 col-xs-12 text-center p-10'}`}>
							{prod.model === 'W73P' &&
								<div className="bg-dark text-lightest p-10 brad-t-20 text-sm text-uppercase text-center text-500 pb-20 popularity">
									<p className="m-0 text-xs pb-0">
										<span className="icon icon-star-full text-cta pr-3"></span>
										<span className="icon icon-star-full text-cta pr-3"></span>
										<span className="icon icon-star-full text-cta pr-3"></span>
										<span className="icon icon-star-full text-cta pr-3"></span>
										<span className="icon icon-star-full text-cta"></span>
									</p>
									<em>Customer Favourite!</em>
								</div>
							}
							<div id={`prod_${i}`} className={`product bg-light pl-20 pr-20 pt-30 pb-20 brad-20 box-shadow shadow-lighter brad-xxs-10 mb-sm-30 mb-xxs-0 primary-hover ${prod.model !== 'W73P' ? 'mt-50' : ''}`}>
								<h6 className="text-500">
									{prod.name}
								</h6>
								<img src={`/images/product-shots/ecom/${prod.model?.replace(' ', '')}.jpg`} className="mt-10 mb-0 center-block product-photo" />
								{(props.discount ?? 0 > 0)
								  ? <>
										<p className="text-500 text-xl mb-0">
											&pound;{(prod.amount - (prod.amount * props.discount)).toFixed(2)}
										</p>
										<p className="text-sm">
											<span className="text-decoration-line-through">Normally &pound;{prod.amount}</span>
											<span className="text-500 text-cta text-uppercase ml-5">
												Save {props.discount * 100}%
											</span>
										</p>
									</>
								  :									<>
										<p className="text-500 text-xl mb-0">
											{prod.amount > 0 && basket && (basket.package !== null && basket.package !== undefined && basket.package?.users?.qty !== undefined) && basket.package?.users?.qty >= MultiBuyDiscountMin && (MultiBuyDiscount > 0 && VoipHandsetDiscount < MultiBuyDiscount && state.hardwareQty >= basket.package?.users?.qty)
											  ? <span>&pound;{(prod.amount - (prod.amount * MultiBuyDiscount)).toFixed(2)}</span>
											  :												(prod.amount > 0 && VoipHandsetDiscount > 0 && VoipHandsetDiscount > MultiBuyDiscount && prod.qty >= 1)
											      ? <span>&pound;{(prod.amount - (prod.amount * VoipHandsetDiscount)).toFixed(2)}</span>
											      :													prod.amount === 0
											        ? 'FREE'
											        : `£${prod.amount}`
											}

										</p>
										{prod.amount === 0
										  ? <p className="text-sm text-400">
												Already included for all users
											</p>
										  :											<p className="text-sm text-400">
												One-off payment
												{(basket.package?.users?.qty ?? 0) >= MultiBuyDiscountMin && (MultiBuyDiscount > 0 && VoipHandsetDiscount < MultiBuyDiscount && state.hardwareQty >= basket.package!.users.qty) &&
													<>
														<span className="text-500 text-cta text-uppercase ml-5">
															Save {MultiBuyDiscount * 100}%
														</span>
														<span className="pl-5 text-decoration-line-through">Normally &pound;{prod.amount}</span>
													</>
												}
											</p>
										}
									</>
								}

								{prod.amount > 0 &&
									<>
										<div className="row pt-10">
											<div className="col-md-12 text-500">
												<form className={'qty mt-10'} style={{ display: 'inline-block' }}>
													<input
														type="button"
														className="btn btn-default"
														onClick={() => { handleChangeQty(i, true) }}
														value="-"
														disabled={prod.qty === 0}
													/>
													<input
														type="tel"
														id="number"
														className="form-control"
														value={prod.qty}
													/>
													<input
														type="button"
														className="btn btn-default"
														onClick={() => { handleChangeQty(i, false) }}
														value="+"
													/>
												</form>
											</div>
										</div>
									</>
								}

								{prod.amount > 0 &&
									<div className="button-set mt-10">
										<Button
											id="LearnMoreButton"
											variant="link"
											className="text-primary pt-10 d-block text-500 text-sm noline mx-auto mb-20"
											onClick={() => { setState({ ...state, modelId: prod.model ?? '', showProdInfo: true }) }}>
											Learn more <span className="icon icon-arrow-right8 text-xs" />
										</Button>

										{prod.qty > 0 &&
											<p className="mb-20">
												<Button variant="link" className="text-dullest text-xs text-400 brad-20 p-0 underline" onClick={() => { handleRemoveFromBasket(prod, i) }}>
													Remove from basket
												</Button>
											</p>
										}

									</div>
								}

								{props.displayInstalments &&
									<div className="bt-2 pt-15 mt-10 text-400 text-start instalments-payment-options">
										<OverlayTrigger
											trigger={['hover', 'focus']}
											placement="top"
											overlay={renderPopover()}
										>
											<div className="text-sm mb-20 ">
												<div className="w-60px float-start pr-5 pb-5">
													<img src="/images/logo/clearpay-logo2.png" width="60" alt="clearpay" className="mr-5 brad-5" />
												</div>
												<p className="mb-0">
													Pay in 4 instalments of <span className="text-500">&pound;{(props.discount > 0 ? (prod.amount - (prod.amount * props.discount)) / 4 : prod.amount / 4).toFixed(2)}</span>
												</p>
											</div>
										</OverlayTrigger>

										<OverlayTrigger
											trigger={['hover', 'focus']}
											placement="top"
											overlay={renderPopover()}
										>
											<div className="text-sm mb-10 ">
												<div className="w-60px float-start pr-5 pb-5">
													<img src="/images/icons/klarna.svg" width="60" alt="Klarna" className="mr-5 brad-5" />
												</div>
												<p className="mb-0">
													Pay in 3 instalments of <span className="text-500">&pound;{(props.discount > 0 ? (prod.amount - (prod.amount * props.discount)) / 3 : prod.amount / 3).toFixed(2)}</span>
												</p>
											</div>
										</OverlayTrigger>
									</div>
								}
							</div>
						</div>
      )

    )
  }

  function handleReviewPurchase () {
    const toBuy = state.products!.filter(f => f.qty > 0)
    if (toBuy !== undefined || toBuy !== null) {
      let total = 0
      toBuy.forEach((prod, i) => {
        total += prod.qty * prod.amount
      })

      setState({
        ...state,
        showCheckout: true,
        total: props.discount > 0 ? total - (total * props.discount) : total
      })
    }
  }

  function renderComponent () {
    let totalPhones = 0
    state.products?.filter(f => f.qty > 0).forEach((prod, i) => {
      totalPhones += prod.qty
    })

    return (
      props.displayInline === true
        ? <>
					{
						state.isLoading
						  ? <LoadingNotification textColor="text-dark" loadingText={'Loading devices, please wait...'} isLoading={state.isLoading} width={100} height={100} />
						  :							(state.products === null)
						      ? <p>Sorry, no products available</p>
						      :								<>
									{state.showCheckout
									  ? renderCheckout()
									  :										renderProducts()
									}
									<div className="text-center mt-30">
										<Button variant="cta" size="lg" onClick={() => { handleReviewPurchase() }}
											disabled={state.products === undefined || ((state.products.filter(f => f.qty > 0)?.length ?? 0) === 0)}>
											{totalPhones > 1
											  ? 'Purchase Phones'
											  :												'Purchase Phone'
											}
										</Button>
									</div>
								</>
					}

					{state.displayPurchasePhonesModal &&
						<ChoosePayment
							handleParentHideModal={() => { setState({ ...state, displayPurchasePhonesModal: false, showCheckout: false }) }}
							total={calculateVat(state.total)}
							clientId={props.clientId}
							purchaseData={state.key}
							email={(state.email === '' || state.email === null) ? props.userDetails.email : state.email}
							name={(state.name === '' || state.name === null) ? `${props.userDetails.fName} ${props.userDetails.sName}` : state.name}
							mandate={true}
							returnRoute={props.returnRoute ? props.returnRoute : 'customer/dashboard?newPhone=true'}
							newAgreement={false}
							showPaymentOptionsModelFromParent={state.displayPurchasePhonesModal}
							address={props.delivery}
							hardware={true}
							failed={state.failed === true} />
					}
				</>

        :				<Modal
					className="products-modal w-90"
					show={props.showEditDevices}
					onHide={() => { props.handleCloseDevicesModal() }}
				>
					<Modal.Body className="bg-dull pl-sm-30 pr-sm-30 pl-xxs-10 pr-xxs-10">
						<span
							className="close-modal"
							onClick={() => { props.handleCloseDevicesModal() }}
						>
							<span>&times;</span>
						</span>
						<div className="container pl-0 pr-0">
							<div className="text-center mb-50">
								<h3 className="text-500 mt-0">
									Select phones for your business
								</h3>
								<p>
									We highly recommend a VoIP phone when making and receiving
									calls.
								</p>
							</div>
							{
								state.isLoading
								  ? <LoadingNotification loadingText={'Please wait...'} isLoading={state.isLoading} width={100} height={100} />
								  :									(state.products === null)
								      ? <p>Sorry, no products available</p>
								      :										<>
											{state.showCheckout
											  ? renderCheckout()
											  :												renderProducts()
											}
										</>
							}

						</div>
					</Modal.Body>
					<Modal.Footer className="text-center">
						{!props.hideButton &&
							<Button
								variant="cta"
								size="lg"
								className="btn-xs-block mt-30"
								onClick={() => {
								  props.handleUpdateHardware(state.products?.filter(f => f.qty > 0))
								  if (props.isSignup) {
								    props.handleCloseDevicesModal()
								  }
								}}
							>
								{props.buttonText !== undefined ? props.buttonText : 'Update your order'}
							</Button>
						}
						<div className="pt-20 col-xs-12 ">
							<p
								className="text-dark underline cursor-pointer text-sm d-inline-block"
								onClick={() => { props.handleCloseDevicesModal() }}
							>
								Cancel, close this window
							</p>
						</div>

					</Modal.Footer>
				</Modal>
    )
  }

  function renderHandsetInfoModal () {
    if (state.products!.filter(f => f.model === state.modelId)?.length > 0) {
			let price = state.products!.filter(f => f.model === state.modelId)[0].amount
			if (props.discount > 0)
				price = price - (price * props.discount)

      if (state.modelId !== '') {
        return (
					<Modal className="w-1200px" show={state.showProdInfo} onHide={() => { setState({ ...state, showProdInfo: false }) }}>
						<Modal.Body>
							<span className="close-modal" onClick={() => { setState({ ...state, showProdInfo: false }) }}>
								<span>&times;</span>
							</span>
							<div className="row w-1200px mx-auto">
								<div className="col-md-7">
									{renderHandsetInfo(state.modelId, price)}
								</div>
								<div className="col-md-5 text-center pt-30">
									<div className="brad-10 p-30 bg-dull">
										<div className="plans pt-0 mb-20">
											<div className="plan w-100">
												<span className="text-md">
													<span className="text-500">Buy Now </span> For
													<span className="text-cta text-500 text-lg ml-5">£{price.toFixed(2)}</span>
													<span className="text-italic">(+VAT)</span>
												</span>
											</div>
										</div>
										<p className="text-sm mt-10 mb-30">
											Includes
											<span className="text-500"><span className="text-cta ml-5">FREE</span> delivery
												<span className="text-cta">+</span> Full UK Support</span>
										</p>

										{props.displayInstalments &&
											<>
												<OverlayTrigger
													trigger={['hover', 'focus']}
													placement="top"
													overlay={renderPopover()}
												>
													<div className="bg-light b-1 brad-10 p-15 text-dark text-start w-100 text-sm text-400 mb-10">
														<img src="/images/logo/clearpay-logo2.png" alt="clearpay" height="25" className="pr-10"/> Pay in 4 instalments of <span className="text-500">&pound;{(price / 4).toFixed(2)}</span>
													</div>
												</OverlayTrigger>

												<OverlayTrigger
													trigger={['hover', 'focus']}
													placement="top"
													overlay={renderPopover()}
												>
													<div className="bg-light b-1 brad-10 p-15 text-dark text-start w-100 text-sm text-400">
														<img src="/images/icons/klarna.svg" alt="Klarna" className="pr-10"/> Pay later, or in 3 instalments of <span className="text-500">&pound;{(price / 3).toFixed(2)}</span>
													</div>
												</OverlayTrigger>
											</>
										}

										<Button variant="cta" size="lg" onClick={() => { setState({ ...state, showProdInfo: false }) }} className="btn-block mt-30">
											Close &amp; Continue Purchase
										</Button>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
        )
      }
    }
  }

  function renderCheckout () {
    const paymentError = query.get('paymentFailed') !== null

    return (
			<Modal className="address-modal" size="sm" show={state.showCheckout} onHide={() => { setState({ ...state, showCheckout: false, checkoutClosed: true }) }} backdrop="static">
				<Modal.Body>
					<span className="close-modal" onClick={e => { setState({ ...state, showCheckout: false, checkoutClosed: true }) }}>
						<span>&times;</span>
					</span>

					<h3 className="text-500 text-md text-center mb-30">
						Please enter your delivery details below
					</h3>

					{paymentError &&
						<p className="text-danger text-sm text-400 text-center">
							There was a problem with your payment, please try again.
						</p>
					}

					<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
						<AddressLookup
							showDeliveryOnly={true}
							showDeliveryAddress={true}
							padding="p-0"
							deliveryAddress={props.delivery}
							billingAddress={props.delivery}
							handleUpdateAddress={(e) => { handleUpdateAddress(e) }}
						/>
					</div>

					<div className="direction-block dir-down bb-1 mb-0 pt-0 pb-30">
						<FloatingLabel
							label="Notes (i.e. Leave in reception)"
							className="mb-3 text-lg d-flex"
						>
							<Form.Control
								value={state.notes}
								onChange={e => { setState({ ...state, notes: e.target.value }) }}
								type="tel"
								placeholder='Notes' />
						</FloatingLabel>
					</div>

				</Modal.Body>
				<Modal.Footer className="bt-0 text-center pb-30">
					{state.inProgress
					  ? <LoadingNotification loadingText={'Please wait...'} isLoading={state.isLoading} textColor="text-dark"
							width={50} height={50} />
					  :						<>
							<div className="direction-block dir-down bb-1 mb-30 pt-0 pb-30">

								<p className="text-500 mb-20">
									Total amount: &pound;{(state.total).toFixed(2)} +VAT.<br />
								</p>

								<Button variant="cta" className="btn-block mb-20" onClick={() => { handlePurchasePhones(true) }}
									disabled={((props.delivery.add1 === '' || props.delivery.add1 === undefined) || (props.delivery.pcode === '' || props.delivery.pcode === undefined))}
								>
									Pay In Instalments / Pay Later
								</Button>

								<Button variant="outline" className="btn-block" onClick={() => { handlePurchasePhones(false) }}
									disabled={((props.delivery.add1 === '' || props.delivery.add1 === undefined) || (props.delivery.pcode === '' || props.delivery.pcode === undefined))}
								>
									Or, Pay In Full Now
								</Button>
							</div>

							<OverlayTrigger
								trigger={['hover', 'focus']}
								placement="top"
								overlay={renderPopover()}
							>
								<div className="bg-darker brad-10 p-15 text-lightest text-start w-100 mb-10 text-sm ml-10 mr-10">
									Pay in 4 instalments of <span className="text-500">&pound;{(state.total / 4).toFixed(2)}</span>
									<img src="/images/logo/clearpay-logo2.png" alt="clearpay" className="float-end" height="25" />
								</div>
							</OverlayTrigger>

							<OverlayTrigger
								trigger={['hover', 'focus']}
								placement="top"
								overlay={renderPopover()}
							>
								<div className="bg-darker brad-10 p-15 text-lightest text-start w-100 mb-10 text-sm ml-10 mr-10">
									Pay later, or in 3 instalments of <span className="text-500">&pound;{(state.total / 3).toFixed(2)}</span>
									<img src="/images/icons/klarna.svg" alt="Klarna" className="float-end" />
								</div>
							</OverlayTrigger>

							<p className="text-sm mt-10 cursor-pointer underline d-block w-100" onClick={() => { setState({ ...state, showCheckout: false, checkoutClosed: true }) }}>Or, cancel order</p>
						</>}

				</Modal.Footer>
			</Modal>
    )
  }

  function renderPopover () {
    return (
			<Popover title="BNPL!">
				<p className="text-sm text-400 mb-0">
					You'll be able to choose this at checkout.
				</p>
			</Popover>
    )
  }

  function handleRemoveFromBasket (item, i) {
    const prodArr: Hardware[] = state.products ?? []

    prodArr[i].qty = 0
    setState({ ...state, products: prodArr })
    props.handleUpdateHardware(state.products)
  }

  function handleChangeQty (index, isDecrement) {
    let totalHardwareQty = 0
    const newArr: Hardware[] = state.products ?? []
    let currentQty = state?.products?.[index].qty ?? 0

    if (isDecrement) {
      newArr[index] = { ...newArr[index], qty: currentQty -= 1 }
    } else {
      newArr[index] = { ...newArr[index], qty: currentQty += 1 }
    }

    newArr.forEach(function (i) {
      if (i.amount > 0) { totalHardwareQty += i.qty }
    })

    if (state.products?.length ?? 0 > 0) {
      LocalStorageHelper.setItem<Hardware[]>('BasketHardware', newArr)
    }
    setState({ ...state, products: newArr, hardwareQty: totalHardwareQty })
  }

  function handleGetHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson<Hardware[]>(url)
      .then(res => {
        if (res.data) {
          if (basket && basket.isOutbound) {
            let prodArr: Hardware[] = []

            if (!props.hideFree) {
              prodArr = [{
                prodId: 0,
                name: 'App',
                brand: 'SBF',
                amount: 0,
                model: 'App',
                qty: 1
              }, {
                prodId: 0,
                name: 'Softphone',
                brand: 'SwitchboardFREE Softphone',
                amount: 0,
                model: 'Softphone',
                qty: 1
              }]
            }

            prodArr = [...prodArr, ...res.data]
            if (props.hideAccessories === true) { prodArr = prodArr.filter(f => f.provider !== 3 && f.provider !== 4) }

            prodArr.sort((a, b) => b.amount - a.amount)
            prodArr.forEach(function (p) {
              p.qty = basket
                ? basket.hardware.filter(f => f.amount > 0).some(s => s.prodId === p.prodId) ? basket.hardware.find(f => f.prodId === p.prodId)?.qty ?? 0 : 0
                : p.qty = 0
            })

            setState({
              ...state,
              products: prodArr,
              isLoading: false
            })
          } else {
            let prodArr = [...res.data]

            if (props.hideAccessories === true) { prodArr = prodArr.filter(f => f.provider !== 3 && f.provider !== 4) }

            prodArr.sort((a, b) => b.amount - a.amount)
            prodArr.forEach(function (p) {
              p.qty = basket ? basket.hardware.filter(f => f.amount > 0).some(s => s.prodId === p.prodId) ? basket.hardware.find(f => f.prodId === p.prodId)?.qty ?? 0 : 0 : p.qty = 0
            })

            setState({
              ...state,
              products: prodArr,
              isLoading: false
            })
          }
        }
      }).catch(err => { console.error(err) })
  }

  function handlePurchasePhones (instalments) {
    // Purchase phones
		setState({ ...state, inProgress: true, instalments: instalments })

    const url = props.purchaseUrl
    const data = {
      hardware: state.products!.filter(f => f.qty > 0),
      deliveryDetails: props.delivery,
			instalments: instalments === true,
			discount: props.discount
    }

    fetchHelper.postJson<PurchaseResponseDto, any>(url, data)
      .then(res => {
        if (res.data.isSuccess) {
          // Payment taken
          setState({ ...state, showCheckout: false, inProgress: false, name: res.data.name, email: res.data.email })
          props.notify({ message: 'Your phones have been ordered.' })
        } else {
          // show payment screen
          setState({
            ...state,
            key: res.data.key,
            displayPurchasePhonesModal: true,
            showCheckout: false,
            inProgress: false,
            name: res.data.name,
						email: res.data.email,
						failed: true && !instalments
          })
        }
      }).catch(err => { console.error(err) })
  }

  function handleUpdateAddress (address) {
    LocalStorageHelper.setItem('DeliveryAddress', address)

    props.handleDeliveryNameChange?.({ newValue: address.name })
    props.handleDeliveryAdd1Change?.({ newValue: address.add1 })
    props.handleDeliveryAdd2Change?.({ newValue: address.add2 })
    props.handleDeliveryTownChange?.({ newValue: address.add3 })
    props.handleDeliveryCountyChange?.({ newValue: address.add4 })
    props.handleDeliveryPcodeChange?.({ newValue: address.pcode })
  }

  function renderHandsetInfo (model, price) {
    switch (model) {
      case 'X7A': {
        return (
					<>
						<h3 className="text-500 mb-10">
							X7A Android Touchscreen Phone
						</h3>
						<h3 className="text-400 text-dullest mb-30 lead">
							Embrace the comfort of a traditional phone but with all the bells & whistles.
						</h3>
						<p>This high-end phone offers you the best experience with its modern take on the traditional look and feel, coupled with exceptional sound and application ability.</p>
						<p>In technical terms, this phone is based on the Android 9.0 system so is easy to use and navigate. The 7-inch colour screen has touch technology, whilst the phone itself has built-in Bluetooth, a 2.4G/5G wi-fi module and integrates the USB 2.0 interface.</p>
						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>Run your business your way & have as many as 20 VoIP lines from different providers coming all into one phone.</li>
							<li>Have a mini meeting with the 3-way conference feature.</li>
							<li>Ensure all calls are answered by using your phone as a hotspot or central hub to connect any other phones you wish to ring at the same time.</li>
							<li>See what’s what on the 7” capacity colour touch-screen.</li>
							<li>Streamline staff communication by directly dialling up to 112 extension numbers.</li>
							<li>Use the dual gigabit port to connect both your phone & PC to your router with one socket. Use the same wires to boost power with the integrated PoE.</li>
							<li>Get comfortable with your phone by adjusting it to sit at 40 or 50 degrees.</li>
						</ul>

					</>
        )
      }
      case 'T31P': {
        return (
					<>
						<h3 className="text-500 mb-10">
							Standard VoIP Handset
						</h3>
						<h3 className="text-400 text-dullest mb-30 lead">
							An up-to-date phone that is neither costly nor complicated.
						</h3>
						<p>Get the traditional office look with this intelligent VoIP-ready handset. It has been designed so you can seamlessly swap out your old office analogue phones without the need for you and your staff to learn new tech.</p>
						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>User-friendly, just pick up & talk.</li>
							<li>You can sit at any desk with the trouble-free plug & play technology.</li>
							<li>There’ll be no need to squint with the clear 2.3" 132 x 64-pixel graphical LCD.</li>
							<li>Grow your business with 2 VoIP phone lines.</li>
							<li>Feel free to put your new phone on a desk or on the wall.</li>
							<li>Let your customers know it's you calling by presenting your SwitchboardFREE number on your outbound calls.</li>
							<li>Have peace of mind with Industrial Standard Certifications: CE/FCC.</li>
						</ul>
					</>
        )
      }
      case 'X4': {
        return (
					<>
						<h3 className="text-500 mb-10">
							Advanced VoIP Handset
						</h3>
						<h3 className="text-400 text-dullest mb-30 lead">
							Separate business from pleasure with a smart desk phone.
						</h3>
						<p>Regain your work-life balance and move away from the distraction of business mobile phones. This professional-looking handset is a convenient alternative to the reliance on mobiles as it has the ability to access your cloud-based contact list in real time. You can also programme touch-call buttons for priority numbers and more besides!</p>

						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>Plug & play technology offers the flexibility to work from the office or at home.</li>
							<li>Avoid asking your customer to repeat themselves with crystal clear HD audio providing clarity with every call.</li>
							<li>Have the flexibility to make & receive calls from your SwitchboardFREE account.</li>
							<li>Let your customers know it's you calling by presenting your SwitchboardFREE number on your outbound calls.</li>
							<li>Easily keep in contact with your staff with free calls between handsets & mobile app (globally).</li>
							<li>Give your customers an efficient service by redirecting incoming calls to any handset or app in your account.</li>
						</ul>
					</>
        )
      }
      case 'W73P': {
        return (
					<>
						<h3 className="text-500 mb-10">
							Cordless VoIP Handset
						</h3>
						<h3 className="text-400 text-dullest mb-30 lead">
							Get the freedom of being mobile without the business-mobile cost.
						</h3>
						<p>Supplying your staff with a company mobile phone can be expensive, and setting them up for work-only use can be difficult too. This cordless handset is the perfect alternative as it can be used flexibly anywhere within your office. Plus the push buttons will get you feeling nostalgic for those pre-touchscreen mobile phones we all had!</p>

						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>Answer & go with this easy-to-use phone with an unobtrusive 1.8" colour display.</li>
							<li>Say “Pardon sir” no more by hearing every call with exceptional HD sound.</li>
							<li>Avoid battery life anxiety with 5-10 hours of talk time or 100 hours of standby time.</li>
							<li>Make communications seamless with features like intercom, transfer, & call forwarding.</li>
							<li>Keep your staff updated with free calls between handsets & app, globally.</li>
							<li>There’s no need for a power adapter with the integrated PoE Class 1.</li>
							<li>Have the flexibility to make & receive calls from your SwitchboardFREE account.</li>
							<li>Let your customers know it's you calling by presenting your SwitchboardFREE number on your outbound calls.</li>
						</ul>
					</>
        )
      }
      case 'H360': {
        return (
					<>
						<h3 className="text-500 mb-10">
							VoIP compatible headsets
						</h3>
						<h3 className="text-400 text-dullest mb-30 lead">
							Be hands-free & noise free with this light & comfortable headset.
						</h3>
						<p>Your customer will hear you perfectly over the noisiest of offices or call centres with noise-cancelling technology. And you will be able to hear them perfectly too because the fully adjustable boom delivers crystal clear audio thus a professional customer experience every time.</p>

						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>Perfect for use with the SwitchboardFREE softphone.</li>
							<li>Easy to use, just plug & play with either the USB or 3.5mm jack connections.</li>
							<li>Block out any unwanted noise with the noise-cancelling microphone.</li>
							<li>Because of the ultra-light & breathable material ear cushion, you will be hands-free & comfortable all day long.</li>
							<li>Control is at your fingertips with the multi-functional panel for volume, microphone mute & speaker mute. (USB only).</li>
							<li>Completely portable due to its foldable design.</li>
						</ul>
					</>
        )
      }

	  case 'WF20': {
        return (
					<>
						<h3 className="text-500 mb-10">
						Fanvil USB WiFi Dongle
						</h3>
						<h4 className="text-400 text-dullest mb-30">
						Connecting your VoIP phones to any available wireless networks.
						</h4>
						<p>
						Your USB Wi-Fi dongle allows you to dispense with tangled wires and use your X4 phone anywhere that has Wi-Fi. Although small, it packs a powerful transmission rate of 150 Mbps ensuring maximum connectivity. Just plug it into your X4 phone and you’re good to go!
						</p>

						<ul className="styled-list cta rounded text-400 mt-30 pl-0">
							<li>
							Compatible with our VoIP Desk Phone
							</li>
							<li>
							Easy to use, just plug &amp; play 
							</li>
							<li>
							Flexible &amp; mobile 
							</li>
							<li>
							Uses very little power 
							</li>
							<li>
    						High transmission rate to keep you reliably connected 
							</li>
							<li>
							Compliant with the IEEE802.11n, IEEE802.11b/g, and 802.11ac standards 
							</li>
						</ul>
					</>
        )
      }
    }
  }

  return renderComponent()
}

export default connect(
  (state: ReduxState) => {
    const deliveryData = state.sbfApp.deliveryData

    return ({
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userFirstName: getFirstName(state),
      userDetails: state.sbfApp.authentication
    })
  }, {
    notify: handleNotificationChange,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange
  }
)(PurchasePhones)
