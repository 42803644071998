import React from 'react'
import { OverlayTrigger, Popover, Ratio} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import Features from '../components/Features/Features'
import Hero from '../components/Home/Hero'
import IntegrationsSlider from '../components/Home/IntegrationsSlider'
import CarouselWidget from '../components/Reviews/CarouselWidget'
import TextBannerWidget from '../components/Reviews/TextBannerWidget'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import StatsBanner from '../components/StatsBanner'
import Testimonials from '../components/Testimonials'
import { FanvilProdId, t31p, w73p, x7a } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { getQueryStringByParameter } from '../helpers/utils'
import { isLoggedIn } from '../selectors'
import { handleShowCallbackForm } from '../store/SBF'
import moment from 'moment'
import { getCustomerCount } from '../helpers/generic'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
		<p>
			<img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
		</p>
	</Popover>
)

class HomePage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
	}

  render () {
		return <><ScrollToTopOnMount /><DisplayMainFooter /><DisplayMainHeader />
			<Helmet>
				<title>Virtual Phone Numbers, Business VoIP Phone Systems, Virtual Landlines</title>
				<meta name="description" content={'Join over ' + this.state.totalCustomers + " businesses who use the UK's favourite call management system. Find your perfect business phone number."} />
			</Helmet>
			<header>
				<Hero
					history={this.props.history}
					version={2}
				/>
			</header>
			<div className="full-width bg-light pt-30">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<span className="text-lg text-500">Trusted by over {this.state.totalCustomers} UK businesses like yours...</span>
							<TextBannerWidget className="text-dark" />
						</div>
					</div>
				</div>
			</div>

			{moment().month() === 10 && // Auto hide after Nov
				<div className="full-width bg-dark pb-60 pt-60 black-friday-bg">
					<div className="container text-center">
						<div className="col-12 col-sm-10 col-md-8 mx-auto">
							<img src="/images/campaigns/blackfriday/2023/homepage-image.png" className="img-responsive d-none d-sm-block cursor-pointer" alt="25% Off Unlimited Until 30th Nov" onClick={() => this.handleSignup(true)} />
							<img src="/images/campaigns/blackfriday/2023/homepage-image-mobile.png" className="img-responsive d-block d-sm-none cursor-pointer" alt="25% Off Unlimited Until 30th Nov" onClick={() => this.handleSignup(true)} />
						</div>
						<div className="col-12 pt-20">
							<Button variant="lightest" className="p-15 pl-50 pr-50 text-md mb-xs-20" onClick={() => this.handleSignup(true)} id="GetStartedButtonBf">
								Claim my 25% discount
							</Button>
						</div>
					</div>
				</div>
			}

			<div className="full-width bg-dull pb-60 pt-60">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center pb-40">
							<h2 className="text-500 text-darker">Over 40 Features Included Free</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block">
								Whichever plan you choose you’ll get more than 20 professional call management features to help you run your business.  <Link to="/features" id="LearnMoreLink" className='underline'>Learn more <span className="icon icon-arrow-right8 text-xs" /></Link>
							</h2>
						</div>
					</div>
				</div>
				<Features FeaturesSlider={true} history={this.props.history} />

				<div className="container">
					<div className="row justify-content-center">
						<div className="col-10">
							<div className="bg-light brad-10 box-shadow-v3 p-30 clearfix mt-50 mb-30">
								<span className="d-inline-block float-start text-darker">
									<span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
										Choose from 0203, 0207, 01, 0333, 0800 or 0808
									</span>
									<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
										<span className="text-cta">Choose your number</span> &amp; get started
									</p>
								</span>

								<span className="d-inline-block float-end">
									<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()} id="GetStartedButton2">Get started</Button>
									<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
								</span>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className="full-width pt-50 pb-50 cyber-banner">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-xs-12 col-md-3 text-center">
									<img width="300" className="img-responsive" src="/images/cyber-essentials.png" alt="SwitchboardFREE is Cyber Essentials Certified"/>
								</div>
								<div className="col-xs-12 col-md-9 pt-xs-20 pt-sm-20">
									<h5 className="text-lightest text-400 pl-md-20">SwitchboardFREE is fully accredited “Cyber Essentials” company. This government-backed certification is awarded to companies who follow the core principles of cyber security.</h5>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-60 pt-60" style={{ backgroundColor: '#d6e8ef' }}>
				<div className="container" style={{ width: '2000px', maxWidth: '100%' }}>
					<div className="row">
						<div className="col-12 text-center pt-40 pb-40">
							<h2 className="text-500 text-darker">Extensive Range of VoIP Phones Available</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block">
								Choose your perfect pre-configured handset so you can plug in &amp; instantly start making & receiving calls <br className='d-block d-sm-none'/><Link to="/phones-apps" className='underline'>Discover more <span className="icon icon-arrow-right8 text-xs" /></Link>
							</h2>
						</div>
					</div>

					<div className="row row-flex flex-4-col flex-sm-2-col no-flex-grow flex-sm-grow d-flex align-items-stretch justify-content-center">
						<div className="col-xs-3 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light pb-85 pb-xs-30">
								<img src="/images/product-shots/ecom/W73P.jpg" alt="Cordless VoIP Phone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-20">Cordless VoIP <br className="d-block d-xs-none d-sm-none d-md-block" />Handset</h4>
								<p>A great choice if your desk is not near your router or you like freedom of movement whilst in the office.</p>

								<Button variant="cta" size="md" className="btn-xxs-block mt-20" onClick={() => this.handleAddHandset(w73p)} id="WirelessHandsetOrderNowButton">Order Now</Button>
								<Link to="/products/yealink-w73p-cordless-phone" className="text-primary pt-10 d-block text-500 text-sm noline" id="WirelessHandsetLearnMoreLink">
									Learn more <span className="icon icon-arrow-right8 text-xs" />
								</Link>
							</div>
						</div>
						<div className="col-xs-3 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light pb-85 pb-xs-30">
								<img src="/images/product-shots/ecom/X4U.jpg" alt="Fanvil X4U VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-20">Advanced VoIP <br className="d-block d-xs-none d-sm-none d-md-block" />Handset</h4>
								<p>Top of the range, our VoIP handset has everything you'll ever need from a desktop phone, and more.</p>

								<Button variant="cta" size="md" className="btn-xxs-block mt-20" onClick={() => this.handleAddHandset(FanvilProdId)} id="AdvancedHandsetOrderNowButton">Order Now</Button>
								<Link to="/products/advanced-voip-phone" className="text-primary pt-10 d-block text-500 text-sm noline" id="AdvancedHandsetLearnMoreLink">
									Learn more <span className="icon icon-arrow-right8 text-xs" />
								</Link>
							</div>
						</div>
						<div className="col-xs-3 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light">
								<img src="/images/product-shots/ecom/T31P.jpg" alt="Fanvil X3S VoIP Deskphone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-20">Standard VoIP <br className="d-block d-xs-none d-sm-none d-md-block" />Handset</h4>
								<p>A budget friendly VoIP Phone that offers a superior user experience for home and office use.</p>

								<Button variant="cta" size="md" className="btn-xxs-block mt-20" onClick={() => this.handleAddHandset(t31p)} id="StandardHandsetOrderNowButton">Order Now</Button>
								<Link to="/products/voip-phone" className="text-primary pt-10 d-block text-500 text-sm noline" id="StandardHandsetLearnMoreLink">
									Learn more <span className="icon icon-arrow-right8 text-xs" />
								</Link>

								<div className="d-block">
									<p className="bg-secondary brad-10 pt-5 pb-5 pl-20 pr-20 text-center text-lightest d-inline-block mt-20 mb-0">
										Get it FREE! <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={FreePhoneMoreInfo}>
											<span className="underline cursor-pointer ml-5 text-sm">See how</span>
										</OverlayTrigger>.
									</p>
								</div>
							</div>
						</div>
						<div className="col-xs-3 col-xxs-12 text-center mb-20">
							<div className="b-1 p-30 brad-10 zoom box-shadow-v3 bg-light pb-85 pb-xs-30">
								<img src="/images/product-shots/ecom/X7A.jpg" alt="Fanvil X7A Android IP Video Phone" style={{ maxWidth: '100%', maxHeight: '270px' }} />
								<h4 className="text-500 mb-20">Android Touchscreen <br className="d-block d-xs-none d-sm-none d-md-block" />Handset</h4>
								<p>A high-end intelligent VoIP phone that combines video, apps and HD voice for the ultimate experience.</p>

								<Button variant="cta" size="md" className="btn-xxs-block mt-20" onClick={() => this.handleAddHandset(x7a)} id="AndroidHandsetOrderNowButton">Order Now</Button>
								<Link to="/products/x7a-voip-phone" className="text-primary pt-10 d-block text-500 text-sm noline" id="AndroidHandsetLearnMoreLink">
									Learn more <span className="icon icon-arrow-right8 text-xs" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pb-60 pt-60">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center pb-40">
							<h2 className="text-500 text-darker">Integrate With the Tools You Already Use</h2>
							<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block">
							Get your call data, missed call alerts & voicemails sent directly to where you need them.  <Link to="/features/4" className='underline'>Find out more <span className="icon icon-arrow-right8 text-xs" /></Link>
							</h2>
						</div>
					</div>
				</div>
				<IntegrationsSlider />

				<div className="container">
					<div className="row justify-content-center">
						<div className="col-10">
							<div className="bg-light brad-10 box-shadow-v3 p-30 clearfix">
								<span className="d-inline-block float-start text-darker">
									<span className="text-uppercase text-sm text-400 d-inline-block mb-xs-10">
										Choose from 0203, 0207, 01, 0333, 0800 or 0808
									</span>
									<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
										<span className="text-cta">Choose your number</span> &amp; get started
									</p>
								</span>

								<span className="d-inline-block float-end">
									<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.handleSignup()} id="GetStartedButton3">Get started</Button>
									<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
								</span>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className="full-width bg-light pt-60 pb-60">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-10">

							<div className="row">
								<div className="col-md-6 col-sm-12 order-last">
									<img src="/images/homepage-features-4.jpg" alt="" className="img-responsive mt-40 mt-xs-0" style={{ maxWidth: '600px' }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first">
									<h2 className="text-500 text-darker">Keep Your Number</h2>
									<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
										Happy with your existing landline number? Bring it with you! <Link to="/keep-my-number" className='underline' id="TransferNowLink">Transfer now <span className="icon icon-arrow-right8 text-xs" /></Link>
									</h2>

									<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Porting your number is simple - our team does it for you.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Keeping your number puts &pound;50 of credit in your account.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Transfer can take as little as 7 days.
										</li>
									</ul>

									<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#d6e8ef' }}>
										<p><em>“Awesome customer service from the 'get-go'. I needed to port over a private landline number from SKY. SKY seemed determined to not let it happen and after nearly giving up, SBF came through and sorted it. If I had listened to SKY and not spoke to Zoe then the number would have been lost.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Paul <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/reviews-logo.png" alt="Reviews.co.uk" className="float-end" />
									</div>
									<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.handleSignup()} id="GetStartedButton4">Get started</Button>
								</div>
								<div className="col-12 d-block d-md-none order-last mt-20">
									<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#d6e8ef' }}>
										<p><em>“Awesome customer service from the 'get-go'. I needed to port over a private landline number from SKY. SKY seemed determined to not let it happen and after nearly giving up, SBF came through and sorted it. If I had listened to SKY and not spoke to Zoe then the number would have been lost.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Paul <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/reviews-logo.png" alt="Reviews.co.uk" className="mt-15" />
									</div>
								</div>
							</div>

							<div className="row mt-200 mt-xs-100">
								<div className="col-md-6 col-sm-12 order-last order-md-first text-end">
									<img src="/images/call-transcription.jpg" alt="Call Recording with transcription" className="img-responsive" style={{ maxWidth: '600px' }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first order-md-last">
									<h2 className="text-500 text-darker">Call Recording &amp; Transcription</h2>
									<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
									Record every call & have them transcribed automatically. <Link to="/call-recording" id="CallRecordingReadMoreLink" className='underline'>Read more <span className="icon icon-arrow-right8 text-xs" /></Link>
									</h2>

									<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Call recording &amp; transcription works across all your numbers.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											You can record inbound &amp; outbound calls.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Enable or disable call recording for specific numbers or users.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Add notes, download or listen to your calls online.
										</li>
									</ul>

									<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#f7ffd0' }}>
										<p><em>“Just started to use this service and it works really well. There are a lot of features and the system is flexible, and all manageable from my computer or my phone via the app. Hannah in customer services has...”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Robin <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
									</div>

									<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.handleSignup()}>Talk to us today &amp; Get started</Button>

								</div>
								<div className="col-12 d-block d-md-none order-last mt-20">
									<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#f7ffd0' }}>
										<p><em>“Have been using switchboard for a while now for my phone system. Their android app is really useful and I can make and receive calls using my business number wherever I am now instead of having to use my mobile number!”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Christopher <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
									</div>
								</div>
							</div>

							<div className="row mt-200 mt-xs-100">
								<div className="col-md-6 col-sm-12 order-last ">
									<img src="/images/homepage-features-1.jpg" alt="" className="img-responsive" style={{ maxWidth: '600px' }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first">
									<h2 className="text-500 text-darker">Quick, Easy &amp; Reliable</h2>
									<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
										You get complete control wherever you are with instant activation, a web portal and free iOS & Android apps.  <Link to="/features" className='underline' id="QuickEasyReliableReadMoreLink">Read more <span className="icon icon-arrow-right8 text-xs" /></Link>
									</h2>

									<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											You decide where your calls are diverted to.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Set opening hours & the times when your calls are directed.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Pick from a variety of greetings & on-hold music.
										</li>
									</ul>

									<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#f7ffd0' }}>
										<p><em>“Have been using switchboard for a while now for my phone system. Their android app is really useful and I can make and receive calls using my business number wherever I am now instead of having to use my mobile number!”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Christopher <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
									</div>

									<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.handleSignup()} id="GetStartedButton5">Get started</Button>

								</div>
								<div className="col-12 d-block d-md-none order-last mt-20">
									<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#f7ffd0' }}>
										<p><em>“Have been using switchboard for a while now for my phone system. Their android app is really useful and I can make and receive calls using my business number wherever I am now instead of having to use my mobile number!”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Christopher <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
									</div>
								</div>
							</div>

							<div className="row mt-200 mt-xs-100">
								<div className="col-md-6 col-sm-12 order-last order-md-first text-end">
									<img src="/images/homepage-features-2.jpg" alt="" className="img-responsive mt-40 mt-xs-0" style={{ maxWidth: '600px' }} />
								</div>
								<div className="col-md-6 col-sm-12 order-first order-md-last">
									<h2 className="text-500 text-darker">Work From Anywhere</h2>
									<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
									Make &amp; receive calls from anywhere, on any device. Take advantage of the luxury of working from home, the office, or abroad. <Link to="/features" className='underline' id="WorkFromAnywhereReadMoreLink">Read more <span className="icon icon-arrow-right8 text-xs" /></Link>
									</h2>

									<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											You get UK rates, even if you’re out of the country.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Answer or make calls from any device.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Have unlimited calls between user extensions.
										</li>
									</ul>

									<div className="bg-dull brad-10 p-50 d-none d-md-block" style={{ backgroundColor: '#ffebeb' }}>
										<p><em>“Just started to use this service and it works really well.  There are a lot of features and the system is flexible, and all manageable from my computer or my phone via the app.  Hannah in customer services has been brilliant.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Robin <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/reviews-logo.png" alt="Reviews.co.uk" className="float-end" />
									</div>
									<Button variant="outline" className="btn btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mt-20" onClick={() => this.handleSignup()} id="GetStartedButton6">Get started</Button>
								</div>
								<div className="col-12 d-block d-md-none order-last mt-20">
									<div className="bg-dull brad-10 p-20 clearfix" style={{ backgroundColor: '#ffebeb' }}>
										<p><em>“Just started to use this service and it works really well.  There are a lot of features and the system is flexible, and all manageable from my computer or my phone via the app.  Hannah in customer services has been brilliant.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Robin <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/reviews-logo.png" alt="Reviews.co.uk" className="mt-15" />
									</div>
								</div>
							</div>

							<div className="row mt-200 mt-xs-100">
								<div className="col-md-6 col-sm-12 order-last">
								<Ratio aspectRatio="16x9" >
									<iframe width="560" height="315" className='brad-30' src="https://www.youtube.com/embed/9G36njgm3hE?si=9USEaRr_JUxuyC5v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
								</Ratio>
									{/* <img src="/images/homepage-features-3.jpg" alt="" className="img-responsive mt-30 mt-xs-0" style={{ maxWidth: '600px' }} /> */}
								</div>
								<div className="col-md-6 col-sm-12 order-first ">
									<h2 className="text-500 text-darker">Everything in One Place</h2>
									<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-600px">
										Call recordings, voicemails, transcriptions &amp; call reporting. Plus more than 20 free additional features to upscale &amp; improve your business. <Link to="/features" className='underline' id="ViewFeaturesLink">View features <span className="icon icon-arrow-right8 text-xs" /></Link>
									</h2>

									<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Instantly receive your voicemails via email.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Have easy access to all call recordings & searchable transcriptions.
										</li>
										<li className="text-md mb-10">
											<span className="icon icon-checkmark-circle text-success mr-10" />
											Experience peace of mind that your customers receive quality service with your new phone system.
										</li>
									</ul>

									<div className="bg-dull brad-10 p-50 d-none d-md-block">
										<p><em>“Excellent company. Excellent service. They could not be more helpful. Their system works faultlessly and very professionally. Highly recommended.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Stuart <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="float-end" />
									</div>
								</div>
								<div className="col-12 d-block d-md-none order-last mt-20">
									<div className="bg-dull brad-10 p-20 clearfix">
										<p><em>“Excellent company. Excellent service. They could not be more helpful. Their system works faultlessly and very professionally. Highly recommended.”</em></p>

										<span className="text-500 pt-10 d-inline-block">
											Stuart <img src="/images/reviews-verified.png" alt="Verified Buyer" className="ml-10" />
										</span>
										<img src="/images/trustpilot-logo.png" alt="Trustpilot" className="mt-15" />
									</div>
								</div>
							</div>

							<div className="row mt-100">
								<div className="col bg-light brad-10 box-shadow-v3 p-30">
									<span className="d-inline-block float-start text-darker">
										<p className="text-500 text-xl mt-0 mb-0 pb-0 mt-neg-10 mb-xs-20">
											<span className="text-cta">Talk to us today</span> &amp; get started
										</p>
										<h2 className="text-lg text-400 mb-0 mt-0 text-dark-light">
											Schedule a quick, no-obligation call &amp; discover...
										</h2>
										<ul className="unstyled text-400 mt-30 mb-30 ml-0 pl-0">
											<li className="text-md mb-10">
												<span className="icon icon-checkmark-circle text-success mr-10" />
												How to upgrade your phone system quickly &amp; easily.
											</li>
											<li className="text-md mb-10">
												<span className="icon icon-checkmark-circle text-success mr-10" />
												How to get a virtual landline number redirected to your mobile.
											</li>
										</ul>
									</span>

									<span className="d-inline-block float-end">
										<Button variant="cta" className="btn btn-cta btn-outline p-15 pl-50 pr-50 text-md mr-30 mr-xs-0 mb-xs-20" onClick={() => this.props.handleShowCallbackForm()}>Get a callback</Button>
										<a href="tel:02031891213" className="btn btn-outline p-15 pl-50 pr-50 text-md mb-xs-30">Call us now</a>
									</span>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="full-width bg-dull pt-60 pb-60" style={{ backgroundColor: '#ffebeb' }}>
				<div className="container text-center">
					<h2 className="text-500 text-darker">See What Our Customers Say</h2>
					<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block mb-30">
						Over the past 17 years, we’ve supported over {this.state.totalCustomers} UK business just like yours.  <Link to="/reviews" id="ReadReviewsLink" className='underline'>Read reviews</Link> and our real life <Link to="/case-studies" id="ReadReviewsLink" className='underline'>case studies</Link>.
					</h2>
					<Testimonials isSlider={true} />
				</div>
			</div>

			<div className="full-width bg-light pt-60">
				<div className="container pl-xs-0 pr-xs-0">
					<div className="row justify-content-center">
						<div className="col-10">
							<div className="brad-10 bg-dull lighter-bg p-50">
								<div className="row">
									<div className="col-md-6 col-xs-12">
										<h2 className="text-500 text-darker mb-30">A Guide to BT's Landline Switch Off</h2>
										<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block mb-30">
											Following BT’s announcement that landlines will be obsolete by 2025, we’ve written a handy guide about what this actually means and how its effects you.<br /><br />
											<Link to="/bt-landline-switchoff-guide" id="ReadOurGuideLink" className='underline'>Read our guide <span className="icon icon-arrow-right8 text-xs" /></Link>
										</h2>
									</div>
									<div className="col-md-6 col-xs-12 text-end">
										<img src="/images/landline-phone-bt.jpg" alt="BT Landline Switch Off" className="img-responsive" style={{ maxWidth: '500px' }} />
									</div>
								</div>
							</div>
						</div>
						<div className="col-10 mt-60 mb-50">
							<div className="row">
								<div className="col-md-6 col-sm-12">
									<div className="brad-10 bg-dull p-50 mb-xs-60" style={{ backgroundColor: '#d6e8ef' }}>
										<h2 className="text-500 mb-30 text-darker">Starting a New Business?</h2>
										<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block mb-30">
											Discover how SwitchboardFREE’s VoIP phone system can help.<br /><br />
											<Link to="/how-to-start-a-business" id="LearnHowLink" className='underline'>Learn how <span className="icon icon-arrow-right8 text-xs" /></Link>
										</h2>
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="brad-10 bg-dull p-50" style={{ backgroundColor: '#f7ffd0' }}>
										<h2 className="text-500 mb-30 text-darker">Using Your Mobile for Business?</h2>
										<h2 className="text-lg text-400 mb-0 mt-10 text-dark-light w-800px center-block mb-30">
											Discover 7 reasons to stop using your mobile for business purposes.<br /><br />
											<Link to="/reasons-not-to-use-mobile-for-business" id="FindOutMoreLink" className='underline'>Find out more <span className="icon icon-arrow-right8 text-xs" /></Link>
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>

					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>
		</>
  }

  handleSignup (isOutbound) {
    this.props.history.push(`/get-started${isOutbound === true ? '?type=1' : ''}`)
  }

  componentDidMount () {
    this.getCustomers()
    this.getHardware()

    const widgetInit = document.createElement('script')
    widgetInit.type = 'text/javascript'
    widgetInit.async = true
    widgetInit.innerHTML = "richSnippetReviewsWidgets('text-banner-widget', {store: 'switchboard-free',starsClr: '#f47e27',textClr: '#595959',logoClr: '#595959', widgetName: 'text-banner', css:'.ReviewsLogo--small .ReviewsLogo__text i {color:#595959 !important;}' });"

    // append to body
    document.body.appendChild(widgetInit)

    const url = window.location.href
    const hero = getQueryStringByParameter('hero', url)
    if (hero) { this.setState({ hero: parseInt(hero) }) }
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]

    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

    this.props.history.push('/get-started')
  }

  async getCustomers () {
	const data = await getCustomerCount()

	this.setState({ totalCustomers:  data})
  }
}
export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  { handleShowCallbackForm }
)(HomePage)
