import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import TermsAndConditions from '../../Text/TermsAndConditions'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import GetProdPrice from '../../components/GetProdPrice'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import { ProductIds, productBillingOption } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../../helpers/utils'
import { handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal, handleUmrEnabledChange } from '../../store/SBF'

class UpgradesUMR extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    showTsAndCs: false, isPurchaseInProgress: false
  }

  render () {
    const yearlyPrice = this.props.yearlyPrice
    const monthlyPrice = this.props.monthlyPrice

    return <><ScrollToTopOnMount/><DisplayLoggedInFooter/><DisplayMainHeader/>
            <header id="start">
				<div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/mobile-redirects-bg.jpg')" }}>
					<h1 className="text-500" id="UMRH1">Unlimited 084 Mobile Redirects</h1>
					<h2 className="subtitle">Ensure you never miss a business call with unlimited redirects to your mobile</h2>
				</div>
			</header>
            <div className="container-styled">
                <div className="container pt-40 pb-40">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-airplane" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">On the go?</h4>
                            <p className="text-start">With a SwitchboardFREE 084 virtual business number and unlimited call routing to mobile
                                that you can manage from all of your devices.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-office" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Out of the office?</h4>
                            <p className="text-start">No problem, divert all of your business calls to one or more mobiles while you are out of
                                the office or on-the-move.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-phone-wave" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Too many calls?</h4>
                            <p className="text-start">Overflow calls you aren't able to answer to one or more
                                mobile numbers. Handle the additional call volumes and never miss a single call.</p>
                        </div>
                        <div className="col-sm-6 col-md-3 pb-30 text-center">
                            <div className="icon-block mt-20">
                                <span className="icon icon-phone" title="local"></span>
                            </div>
                            <h4 className="pt-10 text-500 text-center">Faulty landline?</h4>
                            <p className="text-start">No problem, redirect your business calls to one or more mobiles while your landline is
                                out-of-order and continue running your business as normal.</p>
                        </div>
                    </div>

                </div>

                 {this.props.isEnabled
                   ? <>

                    <div className="full-width bg-secondary text-lightest pt-80 pb-80">
                        <div className="container">
                            <div className="row text-center">
                                <div className="co-md-12 pb-30">
                                    <h2 className="text-500 text-lightest">Great news!</h2>
                                    <p className="text-400">Unlimited 084 Mobile Redirects is already active across all of your 084 numbers.</p>
                                </div>
                                <div className="col-md-12">
                                    <Link to={'/customer/upgrades/'} className="btn btn-cta btn-lg">Other Upgrades</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 text-center pt-30 mb-30">
                                <small>
                                    By purchasing this product you agree to the <a className="text-cta" onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of this product.
                                    <br/>All prices are exclusive of VAT at 20%.
                                </small>
                            </div>
                        </div>
                    </div>
                    </>
                   : <div className="full-width bg-secondary text-lightest pt-60 pb-40">
                        <div className="container">
                            <div className="row text-center">
                                <div className="co-md-12 pb-30">
                                    <h2 className="text-500 text-lightest">Activate Unlimited Mobile Redirects</h2>
                                    <p className="text-md">Please select one of the payment options below.</p>
                                </div>
                                <div className="col-md-12">
                                    <Button variant="cta" size="lg" className="m-5" disabled={this.state.isPurchaseInProgress} onClick={event => this.handlePurchaseUmr(productBillingOption.umrBillingOptionYearly, yearlyPrice)}>
                                        <GetProdPrice productId={ProductIds.umr} yearlyOption={productBillingOption.umrBillingOptionYearly} monthlyOption={productBillingOption.umrBillingOptionMonthly} returnYearly={true} /> <span className="text-xs">per year</span>
                                    </Button>
                                    <span className="pl-20 pr-20 text-400 d-none d-sm-inline">or...</span>
                                    <Button variant="cta" size="lg" className="m-5" disabled={this.state.isPurchaseInProgress} onClick={event => this.handlePurchaseUmr(productBillingOption.umrBillingOptionMonthly, monthlyPrice)}>
                                        <GetProdPrice productId={ProductIds.umr} yearlyOption={productBillingOption.umrBillingOptionYearly} monthlyOption={productBillingOption.umrBillingOptionMonthly} /> <span className="text-xs">per month</span>
                                    </Button>

                                    <div className="text-center pt-30">
                                        <p className="pb-30">Alternatively, you can use our <Link to={'/customer/top-up/'}
                                                                                                  className="text-cta">Top
                                            Up option</Link>.</p>
                                        <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
                                            alt="Payments powered by Stripe" />
                                    </div>
                                    <div className="text-center pt-30 pb-30">
                                        <small>
                                            By purchasing this product you agree to the <a className="text-cta"
                                                                                           onClick={e => this.setState({ showTsAndCs: true })}>terms &amp; conditions</a> of
                                            this product.
                                            <br/>All prices are exclusive of VAT at 20%.
                                        </small>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
                <Modal show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
                    <Modal.Body closeButton>
                        <TermsAndConditions/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {/* END OF: Show the below when UMR is NOT activated */}
            </div>
            <ChoosePayment
                total={calculateVat(this.state.amount)}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  componentDidMount () {
    document.title = 'Unlimited 084 Mobile Redirects'
    const context = this

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        this.enableUmr(context)
      }
    }
  }

  handlePurchaseUmr (billingOption, amount) {
    if (this.props.displayBillingWarning === true) {
      this.props.handleShowBillingWarning()
    } else {
      const context = this
      const url = 'api/Purchase/PurchaseUmr'

      context.setState({ isPurchaseInProgress: true })

      fetchHelper.postJson(url, { billingOption }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          this.enableUmr(context)
        } else if (res.data && res.data.postponed === true) {
          context.props.handleShowPaymentPostponedModal()
          this.enableUmr(context)
        } else {
          context.setState({ amount, key: res.data.key })
          context.props.handleShowPaymentOptionsModal()
        }
      }).catch(function (error) {
        console.error(error)
      })
    }
  }

  enableUmr (context) {
    context.props.notify({ message: 'Unlimited Mobile Redirects Added' })
    context.props.handleUmrEnabledChange(true)
  }
}

export default connect(
  state => {
    return {
      isEnabled: state.sbfApp.enabledServices.hasUmr,
      monthlyPrice: state.home.productDetails.monthlyRepeatPayment,
      yearlyPrice: state.home.productDetails.yearlyRepeatPayment,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      displayBillingWarning: state.sbfApp.enabledServices.displayBillingWarning
    }
  },
  {
    notify: handleNotificationChange,
    handleUmrEnabledChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal,
    handleShowBillingWarning
  }
)(UpgradesUMR)
