import React, { Component } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import SvgRender from '../SvgRender'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  draggable: true,
  arrows: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
}

class Features extends Component {
  state = {
    isOpen: false,
    isOpenTwo: false,
    isOpenTimeOfDayRouting: false,
    isOpenSMS: false,
	isOpenUsersAndTeams: false,
	isOpenTpsProtection: false,
    isOpenThree: false,
    isOpenFour: false,
    isOpenFive: false,
    isOpenSix: false,
    isOpenSeven: false,
    isOpenEight: false,
    isOpenNine: false,
    isOpenTen: false,
    isOpenEleven: false,
    isOpenTwelve: false,
    isOpenThirteen: false,
    isOpenFourteen: false,
    isOpenFifteen: false,
    isOpenSixteen: false,
	isOpenCallRecording: false,
    limit: this.props.limit === undefined ? 3 : this.props.limit
  }

  loadMore (props) {
    this.setState({
      limit: this.state.limit + (props.limit === undefined ? 3 : props.limit)
    })
  }

  renderFeatures (features, nobg) {
    return (
      features.slice(0, this.state.limit).map((item, i) => {
        return (
					<div className="col-sm-6 col-md-4 mb-20">
						<div className={`pt-20 pb-0 text-center ${nobg === true ? '' : 'bg-light'} ${this.props.isModal ? '' : 'pl-30 pr-30 pl-sm-15 pr-sm-15 pl-xs-0 pr-xs-0'}`}>
							<div className="text-center">
								<SvgRender icon={item.icon} svgHeight="50px" svgClassName="mt-25" />
							</div>
							<h4 className="text-500 mt-10 pb-20 text-center">{item.name}</h4>
							<p dangerouslySetInnerHTML={{ __html: item.desc }} ></p>
						</div>
					</div>
        )
      })
    )
  }

  renderFeatureSnippet (text, history, name, url) {
    return (
			<>
				<span dangerouslySetInnerHTML={{ __html: `${text}...` }} />
				<span className="underline text-400 text-primary pl-5 cursor-pointer"
					onClick={() => url === '' ? history.push('/features') : history.push(`/${url}`)} id={`${name.replace(/\s/g, '')}SliderFeaturesReadMoreButton`}>Read more</span>
			</>
    )
  }

  renderSlides (features, history) {
    return (
			<Slider className={`features features-slider ${this.props.hideIcons === true ? 'hide-icons' : ''} `} {...settings}>
				{features.map((item, i) => {
				  return (

						<div className={`${this.props.boxOutsideCustomClass ? this.props.boxOutsideCustomClass : 'text-start m-10'}`}>
							<div className={`${this.props.boxInsideCustomClass ? this.props.boxInsideCustomClass : 'p-20 text-start bg-light brad-10 overflow-auto box-shadow-v3'}`}>
								<div className="w-20 text-center float-start icon pr-20 pt-10">
									<div className="img-circle bg-dull" style={{ width: '55px', height: '55px' }}>
										<SvgRender icon={`${item.icon}`} svgWidth="30px" svgHeight="30px" svgClassName="mt-10" />
									</div>
								</div>
								<div className="w-80 float-start">
									<h4 className="text-500">{item.name}</h4>
									{this.props.showIntro
									  ? <p>
											{this.renderFeatureSnippet(item.intro.slice(0, 80), history, item.name, item.url)}
										</p>
									  :										<p>
											{this.renderFeatureSnippet(item.desc.slice(0, 80), history, item.name, item.url)}
										</p>
									}
								</div>
							</div>
						</div>

				  )
				})}

			</Slider>
    )
  }

  handleSignup (isLoggedIn, history) {
    return isLoggedIn ? '/customer/numbers/new' : '/get-started'
  }

  render () {
    const featureList = [
      {
        icon: 'music-on-hold',
        name: 'On-Hold Music',
        intro: 'Choose from a wide selection of the latest chart music playlists, for free',
        desc: 'Hold music can be predictably dull. Give your customers something to tap their toes to with our large selection of music, including chart hits. It’s updated every two weeks so you can keep your hold music fresh.',
        url: 'music-on-hold'
      },
      {
        icon: 'tps-protection',
        name: 'TPS Protection',
        intro: 'Stay Compliant and Avoid Hefty Fines',
        desc: 'TPS Protection feature provides businesses with an easy and efficient way to stay compliant with TPS regulations. Included for FREE with our Unlimited Plan.',
        url: 'features/tps-protection'
      },
      {
        icon: 'voicemail-transcription',
        name: 'CallSearch Pro',
        intro: 'Search your conversations for a word or phrase. Use negative search to see which calls are missing terms and conditions. A useful tool for auditing, compliance, and training',
        desc: 'Have your calls automatically transcribed into a readable and searchable chat-style format.',
        url: 'features/call-transcription'
      },
      {
        icon: 'call-groups',
        name: 'Teams',
        intro: 'Route inbound calls to a team of people simultaneously over multiple locations',
        desc: 'Easily set up your own call groups to route your customers through to the right people. It’s perfect for directing calls for efficient service especially if you have separate offices or remote staff.',
        url: ''
      },
      {
        icon: 'time-of-day',
        name: 'Time-of-Day Routing',
        intro: 'Divert calls around staff rota’s, opening times and days of the week, never miss a call again',
        desc: 'Set your own bespoke redirects to reflect your business needs on any given day or time, and for any of your SwitchboardFREE numbers.',
        url: ''
      },
      {
        icon: 'departments',
        name: 'Departments',
        intro: "Allow callers to select a department – 'push 1 for sales, 2 for accounts, etc'",
        desc: 'Efficient customer service means getting your callers in contact with the right member of staff the first time. You can make that happen by setting up different departments. I&apos;s easy to do and you can have up to nine departments. This feature is perfect for a growing business or if your staff are split across different locations.',
        url: 'departments-selection'
      },
      {
        icon: 'openclosetimes',
        name: 'Open & Close Times',
        intro: 'Configure open & close times to take a voicemail or record a missed call',
        desc: 'Set open and close times to fit your business hours. Callers phoning outside normal hours will be informed your offices are closed. Optional out-of-hours voicemails are instantly emailed to you.',
        url: 'open-close-your-phone-lines'
      },
      {
        icon: 'custom-prompts',
        name: 'Custom Prompts & Voices',
        intro: 'Greet your customers with outstanding Audio Branding, both powerful and prestigious',
        desc: 'Ensure your customers are greeted the right way, by recording a bespoke introduction, departmental prompt or an out-of-hours message that reflects your opening hours and contact information. For an additonal charge you can choose from a selection of <a href="/custom-prompts/">voices & recordings.</a><span className="text-xs">* Costs apply</span>',
        url: ''
      },
      {
        icon: 'voicemail',
        name: 'Voicemail to Email',
        intro: 'Access your voicemail via email in real-time, wherever you are in the world',
        desc: 'If your customers cannot wait for their call to be answered, they can leave you a voicemail either at the start of their call or after a pre-defined time being on-hold. Your voicemails are instantly emailed to you and are available on the app and web portal.',
        url: ''
      },
      {
        icon: 'redirects',
        name: 'Redirect Your Calls',
        intro: 'Redirect incoming calls easily to your Mobile, Landline, PC or Laptop',
        desc: 'You and your staff can have the flexibility to work from anywhere with the benefit of call redirection. Have your calls redirected to up to five different UK numbers per department, making it possible to divert up to 45 different destinations or call groups including your home or personal mobile number.',
        url: 'redirecting-calls'
      },
      {
        icon: 'call-screening',
        name: 'Call Screening',
        intro: 'Know where your call is from before you answer it, with a short audio message',
        desc: 'With call screening you will hear a short message to let you know the type of call that;s being transferred. For example, “You have a sales call.” This allows you to answer the phone professionally and deal with your customer more efficiently.',
        url: 'call-screening'
      },
      {
        icon: 'queue-calls',
        name: 'Queue Inbound Calls',
        intro: 'Manage busy periods by enabling cloud call queuing, with great on hold music',
        desc: 'Having limited lines into your office can make it difficult to manage calls at peak times. Using a queuing tool will organise your customers, entertained them with great music and put them through as soon as a line becomes free.',
        url: ''
      },
      {
        icon: 'missed-call-alerts',
        name: 'Missed Call Alerts',
        intro: 'Never miss a call with our instant missed call alerts, via the app or an email',
        desc: "If your customer's call can’t be answered, our system will record the caller's phone number and email it to you. This enables you to call back your customer whenever you or your staff are free and have time to give a perfect service.",
        url: 'missed-call-email-alerts'
      },
      {
        icon: 'announce-position',
        name: 'Queue Position Announcement',
        intro: 'Keep your callers connected by notifying them what position they are in the queue',
        desc: 'Being on hold can be frustrating for your customers, so now you can let them know where they are in the queue. This feature is perfect during peak times and will keep your customers informed.',
        url: ''
      },
      {
        icon: 'apps',
        name: 'IOS & Android Apps',
        intro: 'Answer calls and instantly update settings and redirects from anywhere in the world',
        desc: 'You can be anywhere in the world and you’ll still be able to make and receive calls on your iOS or Android phone. You’ll still receive missed call, listen to voicemails and be able to manage your number settings. You can even record custom prompts whilst you’re on the move if you need to.',
        url: 'app'
      },
      {
        icon: 'webhooks',
        name: 'Webhooks',
        intro: 'Sharing call info with your internal systems and existing CRM systems or database systems',
        desc: "<Link to={'/webhooks'}>Webhooks</Link> allows your SwitchboardFREE account to share information with your reporting and database systems. It’s quick and easy to set up and is included in your account absolutely free.",
        url: 'webhooks'
      },
      {
        icon: 'callback-sms',
        name: 'Callback SMS',
        intro: 'Missed a call? Send an apology & explain that you’ll get back to them, priceless customer service',
        desc: 'If your customers call is missed, they will receive a bespoke message from you once they hand up. You can send a short apology, a link to your website or email address or a quick message to say you will get back to them ASAP.',
        url: ''
      }
    ]

    const freeFeatures = window.location.href.toLowerCase().indexOf('/1') > -1
    const premiumFeatures = window.location.href.toLowerCase().indexOf('/2') > -1
    const reportingFeatures = window.location.href.toLowerCase().indexOf('/3') > -1
    const integrations = window.location.href.toLowerCase().indexOf('/4') > -1

    const defaultTab = freeFeatures ? 1 : premiumFeatures ? 2 : reportingFeatures ? 3 : integrations ? 4 : 1

    if (this.props.displayMain === true) {
      return (
		<>
			<div className={`row row-flex flex-nopad features-list ${this.props.isModal ? 'flex-2-col' : 'flex-3-col'}`}>
				{this.renderFeatures(featureList, this.props.nobg)}
			</div>
			{this.state.limit < featureList.length &&
				<div className="w-100">
					<p className="text-center">
						<Button variant="cta" size="sm" className="btn-rounded" onClick={() => this.loadMore(this.props)}>See more</Button>
					</p>
				</div>
			}
		</>
      )
    } else if (this.props.FeaturesSlider === true) {
      return (

				<>
					{this.renderSlides(featureList, this.props.history)}
				</>

      )
    } else {
      return (
				<div className={`tabs-container features ${this.props.isSimpleFeaturesListView === true ? 'features-simple-list' : ''}  ${this.props.hideIcons === true ? 'hide-icons' : ''} ${this.props.noTabs === true ? 'no-tabs' : ''}`} onSelect={(e) => {
				  this.props.history.push('/features/' + e)
				}}>
					<Tabs defaultActiveKey={defaultTab} >
						<Tab eventKey={1} title="Free Features" className="free-features">
							<div className="row row-flex1 features-list align-items-stretch">

							<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="departments" svgWidth="35px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Departments</h4>
										<p>Efficient customer service means getting your callers in contact with the right member of staff the first time. You can make that happen by setting up different departments. I&apos;s easy to do and you can have up to nine departments. This feature is perfect for a growing business or if your staff are split across different locations.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/departments-selection'} className="btn btn-xs btn-secondary mr-10" id="DepartmentsReadMoreButton">Read more</Link>
												<ModalVideo channel='youtube' isOpen={this.state.isOpenEight} videoId='cykYlQGGbmE' onClose={() => this.setState({ isOpenEight: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="DepartmentsChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenEight: true })} className="btn btn-xs btn-secondary" id="DepartmentsWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="openclosetimes" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Open &amp; Close Times</h4>
										<p> Set open and close times to fit your business hours. Callers phoning outside normal hours will be informed your offices are closed. Optional out-of-hours voicemails are instantly emailed to you.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/open-close-your-phone-lines'} className="btn btn-xs btn-secondary mr-10 " id="OpenCloseTimesReadMoreButton">Read more</Link>
												<ModalVideo channel='youtube' isOpen={this.state.isOpenTwo} videoId='W0l_hvSKMUI' onClose={() => this.setState({ isOpenTwo: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="OpenCloseTimesChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenTwo: true })} className="btn btn-xs btn-secondary " id="OpenCloseTimesWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="queue-calls" svgWidth="50px" svgClassName="mt-30" />
										</div>
										<h4 className="text-500 pb-20 text-center">Queue Inbound Calls</h4>
										<p>Having limited lines into your office can make it difficult to manage calls at peak times. Using a queuing tool will organise your customers, entertained them with great music and put them through as soon as a line becomes free.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenFour} videoId='bUbG-2S8WSk' onClose={() => this.setState({ isOpenFour: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="QueueInboundCallsChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenFour: true })} className="btn btn-xs btn-secondary " id="QueueInboundCallsWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="announce-position" svgWidth="40px" svgClassName="mt-30" />
										</div>
										<h4 className="text-500 pb-20 text-center">Queue Position Announcement</h4>
										<p>Being on hold can be frustrating for your customers, so now you can let them know where they are in the queue. This feature is perfect during peak times and will keep your customers informed.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="AnnouncePositionChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="redirects" svgWidth="35px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Redirect Your Calls</h4>
										<p>You and your staff can have the flexibility to work from anywhere with the benefit of call redirection. Have your calls redirected to up to five different UK numbers per department, making it possible to divert up to 45 different destinations or call groups including your home or personal mobile number.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'redirecting-calls'} className="btn btn-xs btn-secondary mr-10" id="RedirectYourCallsReadMoreButton">Read more</Link>
												<ModalVideo channel='youtube' isOpen={this.state.isOpenThree} videoId='F7SQ-xGK9g4' onClose={() => this.setState({ isOpenThree: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="RedirectYourCallsChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenThree: true })} className="btn btn-xs btn-secondary " id="RedirectYourCallsWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="time-routing" svgWidth="60px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Time of Day Routing</h4>
										<p>This feature lets you customise schedules for different departments, holidays, breaks or peak times.</p>
										<p>By routing calls according to your business and staffing needs, you will improve customer satisfaction as they’ll speak to the right person the first time and waiting on hold will be minimal.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="music-on-hold" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">On-Hold Music</h4>
										<p>Hold music can be predictably dull. Give your customers something to tap their toes to with our large selection of music, including chart hits. It’s updated every two weeks so you can keep your hold music fresh.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="MusicOnHoldChooseYourNumbersButton">Create your account</Link>
												<Link to={'/music-on-hold/'} className="btn btn-xs btn-secondary" id="MOHFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-screening" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Screening</h4>
										<p>With call screening you will hear a short message to let you know the type of call that;s being transferred. For example, “You have a sales call.” This allows you to answer the phone professionally and deal with your customer more efficiently.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="CallScreeningChooseYourNumbersButton">Create your account</Link>
												<Link to={'call-screening'} className="btn btn-xs btn-secondary mr-10">Read more</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="custom-prompts" svgWidth="35px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Custom Prompts &amp; Voices</h4>
										<p>Ensure your customers are greeted the right way, by recording a bespoke introduction, departmental prompt or an out-of-hours message that reflects your opening hours and contact information. For an additonal charge you can choose from a selection of
											<a href="/professional-prompts"> voices &amp; recordings.</a>
											<span className="text-xs"> * Costs apply</span>
										</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenSixteen} videoId='BILFP_6Mfkk' onClose={() => this.setState({ isOpenSixteen: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="CustomPromptsChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenSixteen: true })} className="btn btn-xs btn-secondary" id="CustomPromptsWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="missed-call-alerts" svgWidth="45px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Missed Call Alerts</h4>
										<p>If your customer's call can’t be answered, our system will record the caller's phone number and email it to you. This enables you to call back your customer whenever you or your staff are free and have time to give a perfect service.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'missed-call-email-alerts'} className="btn btn-xs btn-secondary mr-10" id="MissedCallAlertsReadMoreButton">Read more</Link>
												<ModalVideo channel='youtube' isOpen={this.state.isOpenSix} videoId='X8dKUN4FP_8' onClose={() => this.setState({ isOpenSix: false })} />
												<a onClick={() => this.setState({ isOpenSix: true })} className="btn btn-xs btn-secondary mr-10" id="MissedCallAlertsWatchVideoButton">Watch video</a>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="MissedCallAlertsChooseYourNumbersButton">Create your account</Link>
												
											</div>
										}
									</div>
								</div>
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="voicemail" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Voicemail to Email</h4>
										<p>If your customers cannot wait for their call to be answered, they can leave you a voicemail either at the start of their call or after a pre-defined time being on-hold. Your voicemails are instantly emailed to you and are available on the app and web portal.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenSeven} videoId='dfyg6ZjSqKM' onClose={() => this.setState({ isOpenSeven: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="VoicemailToEmailChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenSeven: true })} className="btn btn-xs btn-secondary" id="VoicemailToEmailWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="voicemail-transcription" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Voicemail Transcription</h4>
										<p>Have your voicemails automatically transcribed into a readable and searchable chat-style format.</p>
										<p>Keep records of your voicemails in a text format that allows you to search for specific information, copy, paste and share transcriptions to use as your business requires.</p>
										{!this.props.hideButtons &&
											<div className="center-block text-center">
												<div className="button-set">
													<Link to='/get-started' className="btn btn-xs btn-cta" id="VoicemailChooseYourNumbersButton">Create your account</Link>
												</div>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="international-calls" svgWidth="70px" svgClassName="mt-15" />
										</div>
										<h4 className="text-500 pb-20 text-center">International Calling</h4>
										<p>Your business activities don’t need to be solely restricted to the UK. SwitchboardFREE allows you to make and receive calls to and from almost anywhere in the world.</p>
										<p>To enable this feature on your account <Link to={'/contact/'}>contact</Link> our friendly support team and they will advise you on any additional costs per minute that may apply.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-on-hold" svgWidth="40px" svgClassName="mt-30" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Hold & Resume</h4>
										<p>At times you or your staff may need to converse with colleagues or find additional information whilst on a call with a customer. </p>
										<p>Being able to put your caller on hold and listen to entertaining music while you do this will show you are professional and will make the waiting period seem shorter. Once you’ve gathered what you need, you can resume the call and converse once again with the customer. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-transfer" svgWidth="75px" svgClassName="mt-25 ml-5" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Transfers, Blind & Assisted</h4>
										<p>At SwitchboardFREE we give you 2 options when transferring a call to a department or colleague. Option 1 is a blind transfer whereby you directly transfer your caller to another staff member to handle instantly. </p>
										<p>Option 2 is an assisted transfer where you transfer your caller but talk to the recipient colleague to prepare them first. At this point, you can release the caller to your team member or take the call back if you need to.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="dnd" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Do Not Disturb</h4>
										<p>When you or your staff have to prioritise other tasks over answering the phone, SwitchboardFREE’s do-not-disturb feature will temporarily stop calls coming through to you.</p>
										<p>This is available on our App, softphone and all our handsets. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<svg id="icon_make-receive_selected" xmlns="http://www.w3.org/2000/svg" className="mt-25" style={{ width: '50px' }} viewBox="0 0 36.94 38.108">
												<path id="Path_38" data-name="Path 38" d="M127.856,142.965a6.462,6.462,0,0,1,3.442.978l1.673,9.445-2.557,1.323-1.492.772.677,1.537a18.044,18.044,0,0,0,9.867,9.826l1.558.691.766-1.523,1.275-2.537,9.439,1.672a6.556,6.556,0,0,1-5.564,10.016A25.675,25.675,0,0,1,121.3,149.524a6.569,6.569,0,0,1,6.559-6.559m0-1.789a8.358,8.358,0,0,0-8.348,8.348,27.463,27.463,0,0,0,27.431,27.431,8.344,8.344,0,0,0,6.91-13.018,1.188,1.188,0,0,0-.786-.5l-10.127-1.794a.978.978,0,0,0-.208-.015,1.172,1.172,0,0,0-1.053.638l-1.483,2.951a16.162,16.162,0,0,1-8.956-8.911l2.951-1.527a1.194,1.194,0,0,0,.623-1.26l-1.794-10.127a1.261,1.261,0,0,0-.5-.786,8.271,8.271,0,0,0-4.656-1.423Z" transform="translate(-119.508 -138.846)" fill="#ff9314" />
												{/* <path id="Path_39" data-name="Path 39" d="M313.839,185.079l-1.362-1.362-8.537,8.537v-3.8h-1.927v7.087H309.1v-1.927h-3.8Z" transform="translate(-276.898 -175.533)" fill="#4aa9b5" /> */}
												<path id="Path_40" data-name="Path 40" d="M253.478,134.3v2.3h4.052l-9.395,9.395,1.629,1.629,9.395-9.395v4.052h2.3V134.3Z" transform="translate(-230.435 -134.296)" fill="#4aa9b5" />
											</svg>
										</div>
										<h4 className="text-500 pb-20 text-center">Outbound Dialer</h4>
										<p>
											Use the outbound dialer feature to easily and conveniently share out your customer number lists with your staff so they can make quick contact.
										</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/outbound-dialer'} className="btn btn-xs btn-secondary mr-10 " id="OutboundDiallerReadMoreButton">Read more</Link>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="OutboundDiallerChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="hello" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">AI Caller Greeting</h4>
										<p>Give your customers a more personal experience with a powerful speech engine that will welcome people in your Contact Book by the callers by name. Your customers will feel welcomed every time they dial and it won’t cost you any extra.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="CallerGreetingChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>
								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="virtual-numbers" svgWidth="61px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Virtual Phone Numbers</h4>
										<p>Choose from a large variety of UK virtual numbers to put your business in front of your ideal audience. From <Link to={'/local-phone-numbers/'}>local, city</Link> and <Link to={'/03-numbers/'}>nationwide</Link> to <Link to={'/0800-numbers/'}>free-to-call</Link>, SwitchboardFREE offers them all.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to='/number-types' className="btn btn-xs btn-secondary mr-10">Compare numbers</Link>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="VirtualPhoneNumbersChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-groups" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Teams</h4>
										<p>Easily set up your own call groups to route your customers through to the right people. It’s perfect for directing calls for efficient service especially if you have separate offices or remote staff.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenFifteen} videoId='F7SQ-xGK9g4' onClose={() => this.setState({ isOpenFifteen: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="TeamsChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenFifteen: true })} className="btn btn-xs btn-secondary" id="TeamsWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="config-changes" svgWidth="60px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Instant Config Changes</h4>
										<p>Have the flexibility to change your settings at any time instantly. It’s convenient if you’re busy in meetings or staff schedules change and calls can’t be answered. You can re-direct calls, switch on voicemail or close your lines</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="InstantConfigChangesChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="quick-close" svgWidth="90px" svgClassName="mt-5" />
										</div>
										<h4 className="text-500 pb-20 text-center">Quick Open &amp; Close</h4>
										<p>With a single click you can open and close your number or a specific department. Extremely useful in emergencies.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="QuickCloseChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="apps" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">iOS &amp; Android Apps</h4>
										<p>You can be anywhere in the world and you’ll still be able to make and receive calls on your iOS or Android phone. You’ll still receive missed call, listen to voicemails and be able to manage your number settings. You can even record custom prompts whilst you’re on the move if you need to.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/app/'} className="btn btn-xs btn-secondary mr-10" id="AppsFindOutMoreButton">Find out more</Link>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="AppsChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="webhooks" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Webhooks</h4>
										<p>
											<Link to={'/webhooks'}>Webhooks</Link> allows your SwitchboardFREE account to share information with your reporting and database systems. It’s quick and easy to set up and is included in your account absolutely free.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/webhooks/'} className="btn btn-xs btn-secondary mr-10" id="WebhooksFindOutMoreButton">Find out more</Link>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="WebhooksChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>
								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="contact-book" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Contact Book</h4>
										<p>Keep your numbers to hand by using the  <Link to={'/app/'}>mobile app</Link> to integrate with your phone contacts. Any missed call alerts, voicemails and reports will show up against the contact's name.</p>
										<p>Favourite customers can be given priority even if there’s a queue. Known spam or unfavourable numbers can be manually barred.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>
								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="hd-calls" svgWidth="60px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">HD Quality Calls</h4>
										<p>Having a VoIP call management service means that all your calls are of HD sound quality, and that’s no different with SwitchboardFREE.</p>
										<p>Every conversation with your customers or contacts will be professional, crystal clear and free from disturbance.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-transfer" svgWidth="75px" svgClassName="mt-25 ml-5" />
										</div>
										<h4 className="text-500 pb-20 text-center">Number Transfers</h4>
										<p>When moving providers, if you already have a prominent business number and don’t want the hassle of updating your customers, website, marketing etc then you can bring your number with you when you join SwitchboardFREE</p>
										<p>The process is called <Link to={'/keep-my-number/'}>porting</Link>, it’s free, we’ll give £50 call credit for doing so and the team arrange everything for you. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="internal-calling" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Free Internal Calling</h4>
										<p>Your staff can communicate more effectively by picking up the phone and connecting with other extensions, users or departments</p>
										<p>This can save valuable time and money and is completely free.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="inclusive-calls" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Inclusive Calls</h4>
										<p>Every business is different and at SwitchboardFREE we can organise a call management plan that&apos;s bespoke to you. </p>
										<p>What does remain consistent is that all our customers are offered a package with inclusive minutes, to keep prices lower for you and remove the worry of time spent on calls.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="flexible-working" svgWidth="60px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Flexible Working</h4>
										<p>Flexibility is key when it comes to running a successful business, especially concerning staff. SwitchboardFREE makes that easy for you by allowing you and your staff to work from anywhere; the office, home or abroad, all while still using your business number. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="simultaneous-calls" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Simultaneous Ringing</h4>
										<p>Having all your phones ring at once or in turn can have great benefits to your business. It can lead to faster response times, improved call pickup rates, better call distribution among staff, and ultimately, offer a more responsive and efficient service for your customers. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="unique-list" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Logs</h4>
										<p>With a SwitchboardFREE account, you will have access to a call log for all calls. Find details on call type, call duration, call time, call status and more. You can even search for specific calls using different filters.</p>
										<p>Viewing your calls this way gives you an overview of your business and can highlight where changes may need to be made for efficiency.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-monitor" svgWidth="70px" svgClassName="mt-35" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Monitoring</h4>
										<p>This feature allows you to view all your calls in real time. You will see how many calls are waiting, how long the next person in the queue has been on hold, the average hold time and much more. </p>
										<p>With this information, you can make reactive decisions to ensure efficient staff management and shorter waiting times for customers.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="contact-book" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Alternative Caller ID</h4>
										<p>You can add as many numbers to your account as your business needs and then easily choose which one you’d like to present when making outbound calls. </p>
										<p>This is ideal if you have different numbers for different departments, as the person you call will may be able to detect what it is regarding before they answer.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="auto-answer" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Auto Answer</h4>
										<p>On the SwitchboardFREE Phone app, you can set it to auto-answer calls as they come through, and this has many benefits. </p>
										<p>It removes the need to physically answer the phone which offers fast response times for customers, allows staff to multi-task hands-free and is perfect for employees with disabilities or dexterity challenges. </p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="free" svgWidth="60px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Free Onboarding </h4>
										<p>At SwitchboardFREE we appreciate that not everyone is tech-savvy or has the time to learn a new system. That’s why we ensure that you have free access to our expert team whenever you need it.  </p>
										<p>They are on hand from the beginning and can assist you with your sign-up and set-up process. Our team are happy to help you discover how to use your features to their fullest potential and talk you through changing any of your call management settings. Nothing is too much trouble.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="ContactBookChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

							</div>
						</Tab>
						<Tab eventKey={2} title="Premium Features" className={`${this.props.isPaidFeature === true ? 'hidden' : 'premium-features'}`}>
								<div className={`col-md-12 mb-30 text-lightest ${this.props.isPaidFeature === true ? 'hidden' : ''}`} >
									<div className="p-30 pb-xxs-0 brad-10 featured-feature" style={{ backgroundSize: '585px', backgroundColor: '#29919e', backgroundImage: 'url(/images/call-transcription-promo.png)', backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat' }}>
										<img src="/images/tag2.png" className="tag" />
										<div className="feature-text">
											<h2 className="text-500 text-lightest">Call Transcription & Search</h2>
											<p className="text-500 h5" style={{ lineHeight: '1.4' }}>Have your calls automatically transcribed into <br className="d-none d-md-block" />a readable and searchable chat-style format. </p>
											<p className="pt-10 pb-20">
											Keep records of your calls in a text format that allows you to search for specific <br className="d-none d-md-block" />information, copy, paste and share transcriptions to use as your business requires.
											</p>
											<Link to='/features/call-transcription' className="btn btn-md btn-cta mb-30 btn-xxs-block" id="CallTranscriptionFindOutMoreButton">Find out more</Link>
										</div>
									</div>
								</div>
							<div className="row row-flex1 features-list">
								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-groups" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Multiple VoIP Users</h4>
										<p>Add multiple VoIP users and create Teams. Redirect incoming calls to the whole team rather than a single person.</p>
										<p>Perfect if you want multiple people to make and receive calls using your virtual number(s).</p>
										{!this.props.hideButtons &&
											<div className="center-block text-center">
												<div className="button-set">
													<ModalVideo channel='youtube' isOpen={this.state.isOpenUsersAndTeams} videoId='5EEAqlWXHws' onClose={() => this.setState({ isOpenUsersAndTeams: false })} />
													<a onClick={() => this.setState({ isOpenUsersAndTeams: true })} className="btn btn-xs btn-secondary mr-10" id="MultipleVoipUsersWatchVideoButton">Watch video</a>
													<Link to='/get-started' className="btn btn-xs btn-cta" id="VoipUsersAndTeamsChooseYourNumbersButton">Create your account</Link>
												</div>
											</div>

										}
									</div>
								</div>
								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="tps-protection" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">TPS Protection</h4>
										<p>SwitchboardFREE's TPS Protection feature provides businesses with an easy and efficient way to stay compliant with TPS regulations.</p>
										<p>This ensures that you avoid making unsolicited calls to those who have opted out of receiving them.</p>
										{!this.props.hideButtons &&
											<div className="center-block text-center">
												<div className="button-set">
													<ModalVideo channel='youtube' isOpen={this.state.isOpenTpsProtection} videoId='yn5uz5ieljg' onClose={() => this.setState({ isOpenTpsProtection: false })} />
													<a onClick={() => this.setState({ isOpenTpsProtection: true })} className="btn btn-xs btn-secondary mr-10" id="TpsProtectionWatchVideoButton">Watch video</a>
													<Link to={'/features/tps-protection'} className="btn btn-xs btn-secondary mr-10 " id="TPSProtectionReadMoreButton">Read more</Link>
													<Link to='/get-started' className="btn btn-xs btn-cta" id="TPSProtectionChooseYourNumbersButton">Create your account</Link>
												</div>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="voicemail-transcription" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Search Pro</h4>
										<p>Have your calls automatically transcribed into a readable and searchable chat-style format.</p>
										<p>Keep records of your calls in a text format that allows you to search for specific information, copy, paste and share transcriptions to use as your business requires.</p>
										{!this.props.hideButtons &&
											<div className="center-block text-center">
												<div className="button-set">
													<Link to={'/features/call-transcription'} className="btn btn-xs btn-secondary mr-10 " id="CallTranscriptionReadMoreButton">Read more</Link>
													<Link to='/get-started' className="btn btn-xs btn-cta" id="CallTranscriptionChooseYourNumbersButton">Create your account</Link>
												</div>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="recording" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Recording</h4>
										<p>Capturing your calls is essential for staff training, compliance, and conflict resolution. This feature allows you to record all your calls and log numbers for easy recall.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<a onClick={() => this.setState({ isOpenCallRecording: true })} className="btn btn-xs btn-secondary mr-10" id="CallbackSMSWatchVideoButton">Watch video</a>
												<Link to='/call-recording/' className="btn btn-xs btn-secondary mr-10" id="CallRecordingReadMoreButton">Read more</Link>
												<ModalVideo channel='youtube' isOpen={this.state.isOpenCallRecording} videoId='9G36njgm3hE' onClose={() => this.setState({ isOpenCallRecording: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="CallRecordingCreateAccountButton">Create your account</Link>
												
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-blocking" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Spam Call Blocker</h4>
										<p>Nuisance and cold calls are a waste of your precious time. You can avoid unwanted calls and increase staff efficiency and productivity with our call blocker. It offers you the opportunity to block individual numbers, international numbers and withheld numbers.</p>
										{!this.props.hideButtons &&
											<div className="center-block text-center">
												<div className="button-set">
													<Link to='/get-started' className="btn btn-xs btn-cta" id="SpamCallBlockerChooseYourNumbersButton">Create your account</Link>
												</div>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="call-answering" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Call Answering Service</h4>
										<p><Link to={'/telephone-answering-service/'}>Pocket Receptionist</Link> is the perfect partner for SwitchboardFREE as it offers a professional call answering service with real people. When you need staff cover, when you want to extend your business hours or when you’re experiencing busier times, our receptionist can answer simple queries, take payments, and even make appointments for you.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/telephone-answering-service/'} className="btn btn-xs btn-secondary mr-10" id="CallAnsweringServiceFindOutMoreButton">Find out more</Link>
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="CallAnsweringServiceChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="callback-sms" svgWidth="40px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Callback SMS</h4>
										<p>If your customer's call is missed, they will receive a bespoke message from you once they hang up. You can send a short apology, a link to your website or email address, or a quick message to say you will get back to them ASAP.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenSMS} videoId='7mm3IF3HzVM' onClose={() => this.setState({ isOpenSMS: false })} />
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta mr-10" id="CallbackSMSChooseYourNumbersButton">Create your account</Link>
												<a onClick={() => this.setState({ isOpenSMS: true })} className="btn btn-xs btn-secondary" id="CallbackSMSWatchVideoButton">Watch video</a>
											</div>
										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="fax2email" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Fax to Email</h4>
										<p>You can have faxes diverted directly to your email inbox, making the fax machine, expensive toner and wasted paper a thing of the past.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="FaxToEmailChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="golden-number" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Golden Numbers</h4>
										<p>Give your customers a number that’s catchy and easy to remember with a <i>golden number</i>. Stand out from your competitors while sticking in your customer's minds.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/golden-phone-numbers'} className="btn btn-xs btn-cta">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="multi-number" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Multi-Number Package</h4>
										<p>This package offers you a set of numbers which share the same allowance of minutes, making it a cost-effective way to take advantage of having more than one business number.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="MultiNumberPackagesChooseYourNumbersButton">Create your account</Link>
											</div>

										}
									</div>
								</div>

								<div className={`col-sm-6 col-md-4 mb-20 ${this.props.isPaidFeature === true ? 'hidden' : ''}`}>
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="pro-prompts" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Professional Prompts</h4>
										<p>Greet your customers with a bespoke introduction, out-of-hours message or departmental prompt recorded by the same voice artist as the rest of the SwitchboardFREE system. Let your business be known for a high-quality and consistent call experience.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<ModalVideo channel='youtube' isOpen={this.state.isOpenFive} videoId='BILFP_6Mfkk' onClose={() => this.setState({ isOpenFive: false })} />
												<a onClick={() => this.setState({ isOpenFive: true })} className="btn btn-xs btn-secondary mr-10" id="ProfessionalPromptsWatchVideoButton">Watch video</a>
												<a href="/professional-prompts" className="btn btn-xs btn-secondary" id="OrderYourPromptsButton">Order your prompts</a>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="announcements" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Update Announcements</h4>
										<p>Easily record your own call introductions and advise your callers of any important announcements via the app, then activate your voicemail facility.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta" id="UpdateAnnouncementsChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey={3} title="Reporting Features" className="reporting-features">
							<div className="row row-flex1 features-list">
								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="reporting-features" svgWidth="45px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Reporting Features</h4>
										<p>Select the time and date to get in-depth statistics on all your answered calls, unanswered calls and voicemails. You can discover patterns of calls and customer waiting times to manage your business accordingly.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="ReportingFeaturesChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="clever-list" svgWidth="35px" svgClassName="mt-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Missed Call List</h4>
										<p>Give your sales departments a list of missed calls based on whichever date range you select. This enables you and your team the opportunity to call back customers and avoid missing any potential sales.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="MissedCallListChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="departments-reviews" svgWidth="35px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Department Reviews</h4>
										<p>Discover which of your possible nine departments receives the most calls. By running this report you will be able to make divisive decisions on how your departments are arranged to improve the service you provide.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="DepartmentReviewsChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="busy-times" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Identify Busy Periods</h4>
										<p>Ensure you have the right staff in the right place at the right time by discovering when you’re taking or missing the most calls. You will have the ability to avoid wasting money by arranging your team to make them more efficient and give your customers the best service possible.</p>
										{!this.props.hideButtons &&

											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="IdentifyBusyPeriodsChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="unique-list" svgWidth="40px" svgClassName="mt-30" />
										</div>
										<h4 className="text-500 pb-20 text-center">Unique Caller Lists</h4>
										<p>Instantly create your own database of unique callers. Even if a customer has called you multiple times, this report will only show each customer once. This will be useful if you wish to gauge the response to your campaigns and see how many individuals have contacted you.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={this.handleSignup(this.props.isLoggedIn, this.props.history)} className="btn btn-xs btn-cta  ml-10  mb-xxs-10" id="UniqueCallerListsChooseYourNumbersButton">Create your account</Link>
											</div>
										}
									</div>
								</div>

							</div>
						</Tab>
						<Tab eventKey={4} title="Integrations" className="reporting-features">
							<div className="row row-flex1 features-list">

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/capsule.png" height="100" alt="capsule logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Capsule CRM</h4>
										<p>Our Capsule CRM integration automatically updates your Capsule contacts on your Contact book, App, softphone and desk phones whilst simultaneously logging all of your call activity in the Capsule CRM.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/integrations/capsule-crm'} className="btn btn-xs btn-cta" id="CapsuleCRMFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/logo/pipedrive-crm.png" height="100" alt="capsule logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Pipedrive CRM</h4>
										<p>If you currently use Pipedrive, you can now sync your contacts to SwitchboardFREE and upload call information to your Pipedrive contacts.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/integrations/pipedrive-crm'} className="btn btn-xs btn-cta" id="PipedriveCRMFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/hubspot.png" height="105" alt="hubspot logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">HubSpot CRM</h4>
										<p>Easily integrate your Hubspot with SwitchboardFree to sync your contacts and upload call information.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/integrations/hubspot'} className="btn btn-xs btn-cta" id="HubSpotCRMFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/google-sheets.png" height="105" alt="google sheets logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Google Sheets</h4>
										<p>With Webhooks, you can allow your SwitchboardFREE account to log each call as a row in your Google Sheets account. This will make it easier to generate audit lists and keep centralised call logs.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/google-sheets'} className="btn btn-xs btn-cta" id="GoogleSheetsFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/todo.png" height="105" alt="todo logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Microsoft To-Do</h4>
										<p>Webhooks will allow your SwitchboardFREE account to create tasks in your Microsoft to-do list. This will enable you to keep track of your call activity.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/microsoft-to-do'} className="btn btn-xs btn-cta" id="MicrosoftTodoFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/salesforce.png" height="105" alt="salesforce logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Salesforce</h4>
										<p>Using Webhooks, you can allow your SwitchboardFREE calls to be logged directly into your Salesforce account, and streamline your systems even further.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/salesforce'} className="btn btn-xs btn-cta" id="SalesforceFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/slack.png" height="105" alt="slack logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Slack</h4>
										<p>Webhooks will allow your SwiitchboardFREE account to send messages directly via Slack. This will allow your team to respond quickly to high-value customer calls.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/slack'} className="btn btn-xs btn-cta" id="SlackFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/trello.png" height="105" alt="trello logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Trello</h4>
										<p>Your SwitchboardFREE account can be accessed via Webhooks to log inbound calls into your Trello boards. This allows you to monitor your calls much more easily.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/trello'} className="btn btn-xs btn-cta" id="TrelloFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<SvgRender icon="webhooks" svgWidth="50px" svgClassName="mt-25" />
										</div>
										<h4 className="text-500 pb-20 text-center">Webhooks</h4>
										<p>Webhooks is easy to set up and allows you to share information, such as reporting and database systems, with your SwitchboardFREE account. Webhooks is included free with your account.</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/webhooks/'} className="btn btn-xs btn-cta" id="WebhooksIntegrationsFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

								<div className="col-sm-6 col-md-4 mb-20">
									<div className="bg-light pt-40 pb-60 pl-30 pr-30 brad-10 b-1 h-100">
										<div className="text-center img-circle bg-dull block">
											<img src="/images/webhooks/zoho.png" height="105" alt="zoho logo" className="pt-20 pb-20" />
										</div>
										<h4 className="text-500 pb-20 text-center">Zoho CRM</h4>
										<p>With Webhooks, you can allow your SwitchboardFREE account to log call information into your Zoho account. This will enable you to merge your call traffic records with your CRM</p>
										{!this.props.hideButtons &&
											<div className="button-set">
												<Link to={'/web-hook-integrations/zoho'} className="btn btn-xs btn-cta" id="ZohoCRMFindOutMoreButton">Find out more</Link>
											</div>
										}
									</div>
								</div>

							</div>
						</Tab>
					</Tabs>
				</div>
      )
    }
  }
}

export default withRouter(Features)
