import React, { Component } from 'react'
import { Button, FloatingLabel, Form, InputGroup, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import LoadingNotification from '../../components/LoadingNotification'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleNotificationChange } from '../../store/SBF'

class OutboundDialler extends Component {
  static defaultProps = {}

  static propTypes = {}

  state = {
    loadingLists: true,
    loadingStats: true,
    lists: null,
    stats: null
  }

  render () {
    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /> <DisplayMainHeader />
			<Helmet>
				<title>Outbound Dialler</title>
				<style type="text/css">{`
                        .full-width.my-calls-header {
							height: 300px !important;
						  }
                    
                    `}</style>
			</Helmet>
			<header>
				<div className="full-width my-calls-header">
					<h1 id="OutboundDiallerH1">Outbound Dialler</h1>
					<h2 className="subtitle pb-30">
						Distribute lists of calls that need to be made out to your staff.
						<br className='d-none d-sm-block'/>They wil be alerted that there are calls that need making and be available<br className='d-none d-sm-block'/>
						to convientaly call them with the click of a button.
					</h2>
				</div>
			</header>

			<div className="bg-dull p-50 pl-xs-0 pr-xs-0 pt-xs-30 pb-xs-30">
				<div className="container">
					<div className="row">
						<div className="col-md-5 mb-60">
							<div className="bg-light box-shadow-v3 brad-20 p-30">
								<h4 className="text-500 mb-30">Upload your dial list</h4>

								<p>
									The file you upload must contain the below columns.
								</p>
								<ul className='fancy cta pl-30 text-400'>
									<li className="text-400 pb-10">"Name" - <i>The name of the person being called. This is required a field.</i></li>
									<li className="text-400 pb-10">"Number" - <i>The phone number of the person to be called. This is a required field.</i></li>
									<li className="text-400">"Note" - <i>A note about the person to be called, this will be shown to the
										member of staff making the call. The content of the Notes column can be blank.</i>
									</li>
								</ul>

								<FloatingLabel
									controlId="floatingInput"
									label="List Name"
									className="mb-3"
								>
									<Form.Control type="text" placeholder="New list" value={this.state.listName} onChange={e => this.setState({ listName: e.target.value }) } />
								</FloatingLabel>

								<div className="center-block b-1-dark brad-5 mb-20 p-10 mt-10">
									<input type="file" name="files" />
									<p className="text-sm mt-20 mb-0 underline cursor-pointer"><a href="/files/dialler-import-sample.csv" target="_blank">Download our example CSV file</a></p>
								</div>

								<Button size="lg" variant="cta" className="btn-block" onClick={e => this.handleImportList()}>Upload list</Button>
							</div>
						</div>

						<div className="col-md-7 mb-60">
							<div className="bg-light box-shadow-v3 brad-20 p-30">

								<LoadingNotification isLoading={this.state.loadingLists} width={100} height={100}
									hasFinishedLoading={!this.state.loadingLists} removeOnLoaded={true} textColor="text-dark"
									message='Loading...' />

								{!(this.state.loadingLists) && this.state.lists && this.state.lists.length > 0 &&
									<>
										<h4 className="text-500 mb-30">Your existing lists</h4>

										<div className="row text-400 p-10 pb-5">
											<div className="col-md-3">
												Description
											</div>
											<div className="col-md-2 text-center">
												Total
											</div>
											<div className="col-md-2 text-center">
												Remaining
											</div>
											<div className="col-md-2">
												Status
											</div>
										</div>

										{this.state.lists.map((list, i) => {
										  return <div key={list.id} className="w-100 brad-5 b-1 bg-light box-shadow p-10 mb-10">
											<div className="row">
												<div className="col-md-3 pr-0">
													{this.state.editListName === i
													  ? <>
															<InputGroup className="mb-3">
																<Form.Control
																	type="text"
																	value={this.state.nameToEdit}
																	onChange={e => this.setState({ nameToEdit: e.target.value })} />
																<Button variant="success" size="xs" onClick={() => this.handleEditListName(list.id)}>
																	<i className="icon icon-checkmark4"></i>
																</Button>
															</InputGroup>
														</>
													  :														<>
															{list.name}
															<Button variant="transparent" className="p-0 m-0 ml-10 text-dullest" size="sm" onClick={() => this.setState({ editListName: i, nameToEdit: list.name })}>
																<i className="icon icon-pencil3"></i>
															</Button>
														</>
													}

												</div>
												<div className="col-md-2 text-center pl-0">
													{list.totalRecords}
												</div>
												<div className="col-md-2 text-center">
													{list.remainingToCall}
												</div>
												<div className="col-md-1 pr-0">
													<Toggle
														onClick={() => this.toggleListEnabled(list.id)}
														on={<span>ON</span>}
														off={<span>OFF</span>}
														offstyle="default"
														onstyle="success"
														width="null"
														height="null"
														size="xs"
														className="mb-0"
														active={list.enabled}
													/>
												</div>
												<div className="col-md-4 pl-50">
													<Button size="xs" variant="danger" className="mr-10 d-inline" onClick={() => this.deleteList(list.id)}>Delete</Button>
													<Button size="xs" variant="success" className="d-inline" onClick={() => this.setState({ listToEdit: list })}>Add Records</Button>
												</div>
											</div>
										</div>
										})}
								</>
								}

								{!(this.state.loadingStats) &&
									<div className={`text-400 ${this.state.lists.length > 0 ? 'bt-1 mt-30 pt-30 ' : ''}`}>
										Number of dials made today: <a className="underline text-500 text-cta" onClick={() => this.downloadCallResults() }>{this.state.stats.callDoneToday}</a>
										<br />
										Number of dials remaining to do: <a className="underline text-500 text-cta" onClick={() => this.downloadRemainingCalls() }>{this.state.stats.callsRemaining}</a>
										<br/>

									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			{this.state.listToEdit &&
				<Modal show={this.state.listToEdit} className="modal-small" onHide={() => this.setState({ listToEdit: undefined })}>
					<Modal.Header closeButton>
						<Modal.Title>Add records to {this.state.listToEdit.name}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						{this.state.loadingLists
						  ? <LoadingNotification isLoading={this.state.loadingLists} width={50} height={50}
								hasFinishedLoading={!this.state.loadingLists} removeOnLoaded={true} textColor="text-dark"
								message='Please wait...' />
						  :							<>
								<div className="center-block b-1-dark brad-5 mb-20 p-10 mt-10">
									<input type="file" name="modalFile" id="modalFile" />
									<p className="text-sm text-start mt-5 mb-0 underline cursor-pointer"><a href="/files/dialler-import-sample.csv" target="_blank">Download our example CSV file</a></p>
								</div>

								<Button variant="cta" className="mx-auto" onClick={() => this.handleImportList(this.state.listToEdit.id, true)}>
									Import records
								</Button>

								<p className="text-center mt-30 w-100 clearfix">
									<Button size="sm" variant="default" className="mx-auto" onClick={() => this.setState({ listToEdit: undefined })}>
										Close
									</Button>
								</p>

							</>
						}
					</Modal.Body>
				</Modal>
			}

		</>
  }

  handleEditListName (listId) {
    const newName = this.state.nameToEdit

    this.setState({ loadingLists: true, editListName: undefined, nameToEdit: undefined }, () => {
      const url = '/api/OutboundDialler/EditListName'
      fetchHelper.postJson(url, {
        listName: newName,
        listId
      }).then(res => {
        this.setState({ loadingLists: false })
        if (res.data) {
          this.props.notify({ message: 'List name updated.' })
        } else {
          this.props.notify({ message: 'Error changing list name, please contact support', isError: true })
        }
        this.LoadLists()
      }).catch(this.props.notify({ message: 'Error changing list name, please contact support', isError: true }))
    })
  }

  LoadLists () {
    const LoadListsUrl = '/api/OutboundDialler/LoadLists/'
    const context = this

    context.setState({ loadingLists: true })

    fetchHelper.getJson(LoadListsUrl).then(res => {
      const lists = res.data
      if (res.data) {
        context.setState({ loadingLists: false, lists })
      } else {

      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  LoadStats () {
    const LoadStatsUrl = '/api/OutboundDialler/LoadStats/'
    const context = this

    fetchHelper.getJson(LoadStatsUrl).then(res => {
      const stats = res.data
      if (res.data) {
        context.setState({ loadingStats: false, stats })
      } else {

      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  toggleListEnabled (listid) {
    this.setState({ loadingLists: true }, () => {
      const ToggleListsUrl = `/api/OutboundDialler/ToggleList/${listid}`

      fetchHelper.getJson(ToggleListsUrl).then(res => {
        const result = res.data
        this.setState({ loadingLists: false })

        if (result) {
          // toggled ok
          this.props.notify({ message: 'Status updated successfully' })
        } else {
          this.props.notify({ message: 'Error changing your status, please contact support', isError: true })
        }
        this.LoadLists()
        this.LoadStats()
      }).catch(function (error) {
        console.error(error)
      })
    })
  }

  deleteList (listid) {
    this.setState({ loadingLists: true }, () => {
      const ToggleListsUrl = `/api/OutboundDialler/DeleteList/${listid}`

      fetchHelper.getJson(ToggleListsUrl).then(res => {
        const result = res.data
        this.setState({ loadingLists: false })
        if (result) {
          this.props.notify({ message: 'Your list has been deleted.' })
        } else {
          this.props.notify({ message: 'Error deleting your list, please contact support', isError: true })
        }
        this.LoadLists()
        this.LoadStats()
      }).catch(function (error) {
        console.error(error)
      })
    })
  }

  componentDidMount () {
    this.LoadLists()
    this.LoadStats()
  }

  downloadCallResults () {
    const context = this
    fetchHelper.getBlob('api/OutboundDialler/DownloadCallResults/01-01-2010/01-01-2015/').then(response => {
      context.setState({ isLoading: false })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'calls-completed.csv')
      document.body.appendChild(link)
      link.click()
    }).catch(error => console.error(error))
  }

  downloadRemainingCalls () {
    const context = this
    fetchHelper.getBlob('api/OutboundDialler/DownloadRemainingCalls/01-01-2010/01-01-2015/').then(response => {
      context.setState({ isLoading: false })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'calls-remaining.csv')
      document.body.appendChild(link)
      link.click()
    }).catch(error => console.error(error))
  }

  handleImportList (listId, isModal) {
    const context = this
    context.setState({ loadingLists: true })

    let importData
    if (isModal) {
      importData = document.querySelector('#modalFile').files[0]
    } else {
      importData = document.querySelector('input[type="file"]').files[0]
    }

    fetchHelper.postFile(`api/OutboundDialler/Upload/${listId}/${this.state.listName}`, importData).then(response => {
      if (response.data) {
        context.props.notify({ message: 'Your list has been imported' })
        context.setState({ nonImportedNumbers: response.nonImportedRecords, numberImported: response.numberImported, loadingLists: false })
      } else {
        context.props.notify({ message: 'Error importing your list, please contact support', isError: true })
      }
      context.setState({ listToEdit: undefined })

      this.LoadLists()
      this.LoadStats()
    }).catch(function (error) {
      context.props.notify({ message: 'Error importing your list, please contact support', isError: true })
    })
  }
}

export default connect(
  state => {
    return {

    }
  },
  {
    notify: handleNotificationChange
  }
)(OutboundDialler)
