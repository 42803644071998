import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import NavItem from 'react-bootstrap/NavItem'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import ChoosePayment from '../components/purchase/ChoosePayment'
import { SingleTopupDefaultValue, SingleTopupValues } from '../constants/Constants'
import { fetchHelper } from '../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps, simplePhoneNumberFormat } from '../helpers/utils'
import { type ReduxState } from '../models/SignupTypes'
import { getHasAgreement, getMobileCredit, handleHideBillingWarning, handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal, handleUpdateCredit } from '../store/SBF'
import LogoutButton from './LogoutButton'
import ContactDetails from './Profile/YourDetails/ContactDetails'
import RefreshUserServices from './RefreshUserServices'

const _ = require('lodash')

export interface GetProfileContactDto {
  salutation: string
  fname: string
  sname: string
  email: string
  telephone: string
  mobile: string
  fax: string
}

export interface GetProfileDto {
  contactDetails: GetProfileContactDto
  address: GetProfileAddressDto
  companySelectOptions: GetCompanyDetailSelectOptionsDto
  companyDetails: CompanyDetailsDto
  billingDetails: BillingDetailsDto
  optinDetails: OptinDetailsDto
  outboundDetails: ProfileOutboundDetailsDto
}

export interface UpdateSingleMobileTopupResponseDto {
  newCreditAmount: number
  key: string
  success: boolean
  postponed: boolean | null
}

export interface GetProfileAddressDto {
  add1: string
  add2: string
  add3: string
  add4: string
  pcode: string
  client: string
}

export interface SelectBoxOption {
  value: string
  label: string
}

export interface GetCompanyDetailSelectOptionsDto {
  companySize: SelectBoxOption[]
  position: SelectBoxOption[]
  industry: SelectBoxOption[]
}

export interface CompanyDetailsDto {
  companyPositionID: number
  companySizeID: number
  industry: number
}

export interface BillingDetailsDto {
  billingFirstName: string
  billingSurname: string
  billingTitle: string
  billingEmail: string
  billingPhoneNumber: string
}

export interface OptinDetailsDto {
  smsOptedIn: boolean
  phoneOptedIn: boolean
  emailOptedIn: boolean
  postalOptedIn: boolean
}

export interface ProfileOutboundDetailsDto {
  outboundPackageId: number
  landlineSeconds: number
  mobileSeconds: number
  landlineUsedSeconds: number
  mobileUsedSeconds: number
  totalSpend: number
  endPoints: number
  monthlyPrice: number | null
}

export interface LoggedInMenuState {
  showingAddCreditModal: boolean
  singleTopupValue: number
  topupInProgress: boolean
  highlightCredit: boolean
  clickedOutside: boolean
  contactDetails: {}
  topupAmount: number
  key: string
  numbers: Ddi[]
  showPaymentOptionsModal: boolean
}

export interface LoggedInMenuProps extends RouteComponentProps {
  path: string
  mobileCredit: number
  hasAgreement: boolean
  hasContactBook: boolean
  hasVipCaller: boolean
  hasUmr: boolean
  hasCallBlocking: boolean
  isOutboundBeta: boolean
  clientID: number
  hasNewCallBlocking: boolean
  services: Services
  userDetails: UserDetails
  showBillingWarning: boolean
  displayBillingWarning: boolean
  clientId: number
  hasTPSProtection: boolean
  hasCallTranscription: boolean
  handleHideBillingWarning: () => void
  // @ts-expect-error
  notify: ({ message: string }) => void
  handleUpdateCredit: (newCreditAmount: number) => void
  handleShowPaymentPostponedModal: () => void
  handleShowPaymentOptionsModal: () => void
}

export interface Services {
  hasUmr: boolean
  mobileCredit: number
  autoTopup: boolean
  hasAgreement: boolean
  autoTopupAmount: AutoTopupAmount
  hasCallBlocking: boolean
  hasContactBook: boolean
  displayBillingWarning: boolean
  displayUmrUpsell: boolean
  hasCallRecordingAll: boolean
  hasUnlimitedCallRecording: boolean
  hasCallRecordingSingle: boolean
  hasOutboundCalling: boolean
  outboundCallingPackage: null
  isInOutboundBeta: boolean
  hasVipCallerFeature: boolean
  hasNewCallBlockingFeature: boolean
  hasOutboundCallRecording: boolean
  displayOutstandingInvoiceWarning: boolean
  hasTPSProtection: boolean
  hasCallTranscription: boolean
}

export interface AutoTopupAmount {
  label: string
  value: number
}

export interface UserDetails {
  isAuthenticated: boolean
  fName: string
  email: string
  clientID: number
  sName: string
}

export interface Ddi {
  ddiId: number
  number: string

}

class LoggedInMenu extends Component<LoggedInMenuProps, LoggedInMenuState> {
  state: LoggedInMenuState = {
    showingAddCreditModal: false,
    singleTopupValue: SingleTopupDefaultValue,
    topupInProgress: false,
    contactDetails: {},
    clickedOutside: false,
    highlightCredit: false,
    topupAmount: 0,
    key: '',
    numbers: [],
    showPaymentOptionsModal: false
  }

  upgradesNavRoutes = {
    upgradesRoute: '/customer/upgrades/all',
    getNewNumberRoute: '/customer/numbers/new',
    get084Route: '/084-numbers/',
    get0800Route: '/0800-numbers/',
    get0808Route: '/0808-numbers/',
    get03Route: '/03-numbers/',
    get0203Route: '/0203-numbers/',
    get0207Route: '/0207-numbers/',
    getLocalRoute: '/local-phone-numbers/',
    callRecordingRoute: '/customer/upgrades/call-recording/',
    TpsProtectionRoute: '/features/tps-protection',
    memorableNumbersRoute: '/customer/upgrades/memorable-phone-numbers/',
    multiNumbersRoute: '/customer/upgrades/multi-numbers-package',
    topupRoute: '/customer/upgrades/top-up',
    callBlockingRoute: '/customer/upgrades/call-blocking/',
    campaignReportingRoute: '/customer/upgrades/campaign-reporting/',
    unlimitedMobileRedirectsRoute: '/customer/upgrades/unlimited-mobile-redirects/',
    professionalPromptsRoute: '/professional-prompts',
    faxToEmailRoute: '/customer/upgrades/fax-to-email-number/',
    vipCallerRoute: '/customer/upgrades/call-prioritiser/',
    transcriptionRoute: '/customer/call-transcription/',
    keywordTriggerRoute: '/customer/keyword-triggers/',
    conversationSearchRoute: '/customer/conversation-search/'
  }

  callerManagementNavRoutes = {
    contactBookRoute: '/customer/contact-book/',
    callPriotiserRoute: '/customer/call-prioritiser/',
    callBlockerRoute: '/customer/call-blocking/'
  }

  voipPhonesNavRoutes = {
    w53pPhoneRoute: '/products/yealink-w73p-cordless-phone',
    X3PhoneRoute: '/products/voip-phone',
    X4PhoneRoute: '/products/advanced-voip-phone'
  }

  myCallsNavRoutes = {
    alerts: '/customer/my-calls/call-alerts',
    stats: '/customer/my-calls/call-stats',
    voicemails: '/customer/my-calls/voicemails',
    callRecording: '/customer/call-recording',
    outbounDialling: '/customer/outbound-dialler'
  }

  dashboardNavRoutes = {
    dashboardRoute: '/customer/dashboard',
    numbersRoute: '/customer/numbers',
    statsRoute: '/customer/stats/',
    outboundUsersRoute: '/customer/outbound-management',
    outboundTeamsRoute: '/customer/team-management',
    outboundManualsRoute: '/setup/',
    outboundPhones: '/voip-handsets'
  }

  myNumbersNavRoute = {
    listNumbers: '/customer/numbers',
    purchaseNumbers: '/customer/numbers/new'
  }

  myUsersNavRoute = {
    listUsers: '/customer/outbound-management',
    addUser: '/customer/outbound-management/new'
  }

  myTeamsNavRoute = {
    listTeams: '/customer/team-management',
    addTeam: '/customer/team-management/new'
  }

  accountNavRoutes = {
    profileRoute: '/customer/profile/your-details',
    invoiceRoute: '/customer/profile/billing',
    billingRoute: '/customer/profile/billing',
    securityRoute: '/customer/profile/security',
    callRecordingRoute: '/customer/profile/call-recording-settings',
    subscriptionsRoute: '/customer/profile/subscriptions-and-numbers',
    emergencyContactRoute: '/customer/profile/emergency-contacts',
    helpRoute: '/customer/profile/',
    setupRoute: '/setup/',
    crmSettings: '/customer/profile/crm-settings',
    tpsProtectionSettings: '/customer/profile/tps-protection-settings'
  }

  render () {
    if (!String.prototype.includes) {
      String.prototype.includes = function (search, start) {
        'use strict'
        if (typeof start !== 'number') {
          start = 0
        }
        if (start + search.length > this.length) {
          return false
        } else {
          return this.indexOf(search, start) !== -1
        }
      }
    }

    if (this.state.topupInProgress || this.state.showingAddCreditModal) {
      localStorage.setItem('pauseRefresh', 'true')
    } else {
      localStorage.removeItem('pauseRefresh')
    }

    return (
            <>
                <RefreshUserServices />
                {this.renderAdditionalCreditModal()}
                <Nav navbarScroll className="me-auto my-2 my-lg-0">
                    <NavItem>
                        <Nav.Link className="your-credit" onClick={e => { this.setState({ showingAddCreditModal: true }) }}>
                            Your Credit: <span className="text-cta text-500">{this.formatMobileCredit(this.props.mobileCredit)}</span>
                                {this.state.highlightCredit && this.state.highlightCredit && <span className="badge">Low credit</span>}
                        </Nav.Link>
                    </NavItem>
                    <NavItem>
                        <Nav.Link className="add-credit" onClick={e => { this.setState({ showingAddCreditModal: true }) }}>Add Credit</Nav.Link>
                    </NavItem>
                    {/* <li className="your-credit col-xs-6">
                        <a onClick={e => this.setState({ showingAddCreditModal: true })}>
                            Your Credit: <span className="text-cta text-500">{this.formatMobileCredit(this.props.mobileCredit)}</span>
                            {this.state.highlightCredit && this.state.highlightCredit === true && <span className="badge">Low credit</span>}
                        </a>
                    </li>
                    <li className="add-credit bl-1 col-xs-6" role="presentation">
                        <a onClick={e => this.setState({ showingAddCreditModal: true })}>Add Credit</a>
                    </li> */}

                    <NavDropdown title="My Numbers" className={'nav-pairs activeNav'.concat(_.includes(this.myNumbersNavRoute, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">

                        <NavDropdown.Item as={Link} to={this.myNumbersNavRoute.listNumbers} id="NavMenuListNumbers">See all my numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.myNumbersNavRoute.purchaseNumbers} id="NavMenuPurchaseNumber">Add new number</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.memorableNumbersRoute} id="NavMenuGoldNumbers">Add memorable number <span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span></NavDropdown.Item>

                        <span className="p-10 d-block nav-divider">
                            <hr className="dropdown-divider" />
                            {/* <span className="divider-text">Features</span> */}
                        </span>
                        {this.state.numbers.map(number => {
                          return <NavDropdown.Item as={Link} to={'/customer/numbers/' + number.number}>{simplePhoneNumberFormat(number.number.replaceAll('/1',''))}</NavDropdown.Item>
                        })}

                    </NavDropdown>

                    <NavDropdown title="My Users" className={'nav-pairs activeNav'.concat(_.includes(this.myUsersNavRoute, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={this.myUsersNavRoute.listUsers} id="NavMenuListUsers">List users</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.myUsersNavRoute.addUser} id="NavMenuAddUser">Add a user</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.TpsProtectionRoute} id="NavMenuUsersTPSFeature">
                            TPS Protection <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="My Teams" className={'nav-pairs activeNav'.concat(_.includes(this.myTeamsNavRoute, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={this.myTeamsNavRoute.listTeams} id="NavMenuListTeams">List teams</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.myTeamsNavRoute.addTeam} id="NavMenuAddTeam">Add a team</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="My Calls" className={'nav-pairs activeNav'.concat(_.includes(this.myCallsNavRoutes, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={'/customer/calls'} id="NavMenuCalls">Calls</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.myCallsNavRoutes.stats} id="NavMenuCallReports">Call Reports</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.transcriptionRoute} id="NavMenuTranscription">
                                Call Transcription
                                <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.keywordTriggerRoute} id="NavMenuKeywordTriggers">
                            Keyword Triggers
                            <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.myCallsNavRoutes.outbounDialling} id="NavMenuOutboundDialler">Outbound Dialling</NavDropdown.Item>

                    </NavDropdown>

                    <NavItem>
                        <Link className="text-500 nav-link" to={'/phones-apps'} id="NavMenuVoIPPhones">My Phones</Link>
                    </NavItem>

                    <NavDropdown title="Caller Management" className={'nav-pairs activeNav'.concat(_.includes(this.callerManagementNavRoutes, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={this.callerManagementNavRoutes.contactBookRoute} id="NavMenuContactBook">Contact Book</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.callerManagementNavRoutes.callPriotiserRoute} id="NavMenuCallPrioritiser">Call Prioritiser</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.callerManagementNavRoutes.callBlockerRoute} id="NavMenuCallBlocker">Nuisance Call Blocker</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Upgrades" className={'nav-pairs activeNav'.concat(_.includes(this.upgradesNavRoutes, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item className="br-xxs-1" as={Link} to={this.upgradesNavRoutes.upgradesRoute} id="NavMenuAllUpgrades">All Upgrades</NavDropdown.Item>
                        <span className="p-10 d-block nav-divider">
                            <hr className="dropdown-divider" />
                            <span className="divider-text">Numbers</span>
                        </span>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.getLocalRoute} id="NavMenuLocalNumbers">Local UK Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get084Route} id="NavMenu084Numbers">084 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get0800Route} id="NavMenu0800Numbers">0800 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get0808Route} id="NavMenu0808Numbers">0808 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get03Route} id="NavMenu03Numbers">03 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get0203Route} id="NavMenu0203Numbers">0203 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.get0207Route} id="NavMenu0207Numbers">0207 Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.memorableNumbersRoute} id="NavMenuMemorableNumbersUpgrade">Memorable Numbers <span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span><span className='icon icon-star-full text-cta text-xs'></span></NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.multiNumbersRoute} id="NavMenuMultiNumbersUpgrade">Multi-Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.faxToEmailRoute} id="NavMenuFaxToEmailUpgrade">Fax to Email</NavDropdown.Item>

                        <span className="p-10 d-block nav-divider">
                            <hr className="dropdown-divider" />
                            <span className="divider-text">Premium Features</span>
                        </span>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.TpsProtectionRoute} id="NavMenuTPSFeature">
                            TPS Protection <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.callRecordingRoute} id="NavMenuCallRecordingUpgrade">Call Recording</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.vipCallerRoute} id="NavMenuCallPrioritiserUpgrade">Call Prioritiser</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.callBlockingRoute} id="NavMenuCallBlockerUpgrade">Nuisance Call Blocker</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.professionalPromptsRoute} id="NavMenuProfessionalPromptsUpgrade">Professional Prompts</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.unlimitedMobileRedirectsRoute} id="NavMenu084MobileRedirects">084 Mobile Redirects</NavDropdown.Item>

                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.transcriptionRoute} id="NavMenuTranscription">
                            Call Transcription
                            <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>

                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.keywordTriggerRoute} id="NavMenuKeywordTrigger">
                            Keyword Triggers
                            <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>

                        <NavDropdown.Item as={Link} to={this.upgradesNavRoutes.conversationSearchRoute} id="NavMenuConversationSearch">
                            Conversation Search
                            <span className="badge text-lightest bg-danger brad-10 ml-5">NEW</span>
                        </NavDropdown.Item>

                    </NavDropdown>

                    <NavDropdown title="Account" className={'nav-pairs d-inline-block d-lg-none account-dropdown activeNav'.concat(_.includes(this.accountNavRoutes, this.props.path) ? ' active' : '')} id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.profileRoute} id="NavMenuProfile">Contact Details</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.billingRoute} id="NavMenuBilling">Billing &amp; Invoices</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.securityRoute} id="NavMenuSecurity">Security</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.subscriptionsRoute} id="NavMenuSubscriptionAndNumbers">Subscription &amp; Numbers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.callRecordingRoute} id="NavMenuCallRecordingSettings">Call Recording Settings</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.emergencyContactRoute} id="NavMenuEmergencyContacts">Emergency Contact Info</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.setupRoute} id="NavMenuSetup">Setup &amp; Phone Instructions</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.crmSettings} id="NavMenuSetup">CRM Settings</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={this.accountNavRoutes.tpsProtectionSettings} id="NavMenuTpsProtection">TPS Protection Settings</NavDropdown.Item>
                    </NavDropdown>

                    <ContactDetails {...this.state.contactDetails} history={this.props.history} displayUserDetailsOnly={true} />

                    <li className="logout nav-item" role="presentation">
                        <LogoutButton className="nav-link" isMobile={true} />
                    </li>
                </Nav>

                <div className="float-end mt-10 mb-10 d-none d-md-block profile-options">
                    <Button variant="outline" size="sm" className="btn-rounded mr-0 ml-0 text-500 text-sm btn-default" onClick={e => { this.setState({ showingAddCreditModal: true }) }} id="ShowCreditButton">
                        <span className="text-500 text-sm">
                            <span className="text-dark">Credit:</span> <span className="text-cta text-500">{this.formatMobileCredit(this.props.mobileCredit)}</span>
                        </span>
                    </Button>

                    <span className="bg-dullest float-end b-1 img-circle text-center d-block ml-10 profile-icon cursor-pointer" style={{ height: '45px', width: '45px', backgroundImage: 'url(/images/icons/user.svg)' }} id="UserProfileIcon"/>
                </div>

                {(this.state.showingAddCreditModal && this.state.topupAmount > 0) && <ChoosePayment
                    total={this.state.topupAmount}
                    clientId={this.props.clientId}
                    purchaseData={this.state.key}
                    email={this.props.userDetails.email}
                    name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                    mandate={!this.props.services.hasAgreement}
                    isRedirect={false}
                    description="Call Credit"
                    showPaymentOptionsModelFromParent={this.state.showPaymentOptionsModal}
                    handleParentHideModal={() => { this.handleAddCreditToBalance(this.state.topupAmount / 1.2) }} />}

                <Modal show={this.props.showBillingWarning} onHide={() => { this.props.handleHideBillingWarning() }} size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Sorry, we need your payment details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Unfortunately, before you can add more products to your account we need you to setup a billing agreement.</p>
                        <p>
                            Please add your billing details via{' '}
                            <span className="underline cursor-pointer" onClick={() => { this.handleUpdateBilling() }}>
                                your profile page
                            </span>
                        </p>
                        <p className="text-start mt-20 d-flex justify-content-center">
                            <Button onClick={() => { this.handleUpdateBilling() }} variant="cta">
                                Add Billing Details
                            </Button>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <p className="text-sm text-center">
                            Alternatively, you can get in touch with us on <br className="d-none d-md-block" />
                            <a href="tel:02031891213" className="text-cta underline">
                                0203 189 1213
                            </a>{' '}
                            or{' '}
                            <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">
                                live chat
                            </a>
                        </p>
                    </Modal.Footer>
                </Modal>
            </>
    )
  }

  handleUpdateBilling () {
    this.props.handleHideBillingWarning()
    window.location.href = '/customer/profile/billing'
  }

  renderAdditionalCreditModal () {
    return (
            <Modal show={this.state.showingAddCreditModal} onHide={() => { this.setState({ showingAddCreditModal: false }) }}>
                <Modal.Header closeButton className="bb-0 pt-30">
                    <Modal.Title>Purchase Additional Credit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dull col-md-12">
                    <div className="text-center col-md-12">
                        <div className="mb-30 b-1 p-30 brad-30 bg-light">
                            <p className="text-500 mb-0 pb-0">
                                Your current credit is:
                                <br />
                                <span className="text-cta text-md">{this.formatMobileCredit(this.props.mobileCredit)}</span>
                            </p>
                        </div>

                        <div className="mb-30 b-1 p-30 brad-30 bg-light">
                            <p className="text-500 text-md pb-10">One-off Top Up</p>
                            <p>Please select the amount you require.</p>
                            <Button variant="cta" onClick={e => { this.purchaseQuickCredit(10.0) }} disabled={this.state.topupInProgress} className="m-5">
                                +£10.00
                            </Button>
                            <Button variant="cta" onClick={e => { this.purchaseQuickCredit(20.0) }} disabled={this.state.topupInProgress} className="m-5">
                                +£20.00
                            </Button>
                            <Button variant="cta" onClick={e => { this.purchaseQuickCredit(40.0) }} disabled={this.state.topupInProgress} className="m-5">
                                +£40.00
                            </Button>
                        </div>

                        <div className="mb-0 b-1 p-30 brad-30 bg-light">
                            <p className="text-500 text-md  pb-10">Auto Top Up</p>
                            <p>Never worry about running out of credit and missing calls with out Auto Top-Up feature.</p>
                            <p>
                                <Link to={'/customer/upgrades/top-up'} className="btn btn-xs btn-cta m-5" onClick={() => { this.setState({ showingAddCreditModal: false }) }}>
                                    Get Started
                                </Link>
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bt-0 bg-dull clearfix">
                    <div className=" text-center col-md-12 pt-0 pb-30">
                        <small className="text-400">
                            By purchasing this product you agree to the terms and conditions of this product.
                            <br />All prices are exclusive of VAT at 20%.
                        </small>
                    </div>
                </Modal.Footer>
            </Modal>
    )
  }

  formatMobileCredit (mobileCredit) {
    if (!mobileCredit) return '£0.00'
    return `£${mobileCredit.toFixed(2)}`
  }

  componentDidMount () {
    let url = 'api/Profile/GetProfile'
    fetchHelper
      .getJson<GetProfileDto>(url)
      .then(response => {
        const data = response.data

        this.setState({
          contactDetails: data.contactDetails
        })
      })
      .catch(error => { console.error(error) })

    url = 'api/Profile/GetTopFiveNubmers'
    fetchHelper.getJson<Ddi[]>(url)
      .then(response => {
        const data = response.data

        this.setState({
          numbers: data
        })
      })
      .catch(error => { console.error(error) })

    // Check if we need to highlight credit
    const apiUrl = 'api/Users/CheckAndAlertCreditUsage'
    fetchHelper.getJson<boolean>(apiUrl).then(res => {
      this.setState({
        highlightCredit: res.data
      })
    })
    const context = this
    const queryString = getQueryStringFromProps(context.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('newcred=true')) {
        this.props.notify({ message: 'Topup credit added' })
      }
    }
    const concernedElement = document.querySelector('.profile-icon')
    const profileBox = document.querySelector('.profile-box') as HTMLElement
    document.addEventListener('mousedown', event => {
      if (concernedElement?.contains(event.target as Node)) {
        profileBox.style.cssText = 'visibility: visible; opacity: 1'
      } else {
        profileBox.style.cssText = 'visibility: hidden; opacity: 0'
      }
    })
  }

  purchaseQuickCredit (creditToAdd) {
    const context = this
    context.setState({ topupInProgress: true }, () => {
      const url = '/api/Purchase/PurchaseSingleMobileTopup'
      const topupId = _.find(SingleTopupValues, tv => tv.price === creditToAdd).topupId
      fetchHelper
        .postJson<UpdateSingleMobileTopupResponseDto, { TopupOptionId: number }>(url, { TopupOptionId: topupId })
        .then(res => {
          localStorage.removeItem('pauseRefresh')

          if (res.data && res.data.success) {
            context.setState({ showingAddCreditModal: false }, () => {
              context.props.notify({ message: 'Mobile redirect credit added' })
              context.props.handleUpdateCredit(res.data.newCreditAmount)
            })
          } else {
            const priceIncVat = calculateVat(creditToAdd)
            context.setState({
              topupAmount: priceIncVat,
              key: res.data.key,
              showPaymentOptionsModal: true
            })
            // context.props.handleShowPaymentOptionsModal();
          }
        })
        .catch(function (error) {
          context.setState({ topupInProgress: false })
          console.error(error)
        })
    })
  }

  handleAddCreditToBalance (creditToAdd) {
    const context = this

    context.setState({ showingAddCreditModal: false }, () => {
      context.props.notify({ message: 'Mobile redirect credit added' })
      context.props.handleUpdateCredit(creditToAdd)
    })
  }
}
export default connect(
  (state: ReduxState) => {
    return {
      mobileCredit: getMobileCredit(state),
      hasAgreement: getHasAgreement(state),
      hasContactBook: state.sbfApp.enabledServices.hasContactBook,
      hasVipCaller: state.sbfApp.enabledServices.hasVipCallerFeature,
      hasUmr: state.sbfApp.enabledServices.hasUmr,
      hasCallBlocking: state.sbfApp.enabledServices.hasCallBlocking,
      isOutboundBeta: state.sbfApp.enabledServices.isInOutboundBeta,
      clientId: state.sbfApp.authentication.clientId,
      hasNewCallBlocking: state.sbfApp.enabledServices.hasNewCallBlockingFeature,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      showBillingWarning: state.sbfApp.visibility.showBillingWarning,
      displayBillingWarning: state.sbfApp.enabledServices.displayBillingWarning,
      hasTPSProtection: state.sbfApp.enabledServices.hasTPSProtection,
      hasCallTranscription: state.sbfApp.enabledServices.hasCallTranscription
    }
  },
  {
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleUpdateCredit,
    handleShowPaymentPostponedModal,
    handleHideBillingWarning,
    handleShowBillingWarning
  }
)(LoggedInMenu)
