import { type DefaultRootState } from 'react-redux'
import { type RouteComponentProps } from 'react-router-dom'
import {
  type BasketItemType, type MinuteBundle, type numberPackageTypes, type SignupStep,
  type validationStates
} from '../constants/Constants'
import {
  type AddressDto, type NumberType
} from './GenericTypes'

export interface ServiceType {
  outbound: boolean
  inbound: boolean
  numberOnly: boolean
  numberFull: boolean
  showMobileVoipInfo: boolean
  showMobileInboundInfo: boolean
}

export interface Hardware {
  qty: number
  monthlyPayments?: boolean
  monthlyPrice?: any
  amount: number
  prodId: number
  name: string | undefined
  model?: string
  brand?: string | undefined
  provider?: number | undefined
}

export interface EditBasketParams {
  item: BasketItem
  itemType: BasketItemType
  editing: boolean
  removeAll: boolean
}

export interface Basket {
  numbers: SbfNumber[]
  package?: SbfPackage
  hardware: Hardware[]
  isOutbound: boolean
  setupFee?: number
  discountApplied?: number
}

export interface SbfNumber {
  ddiid: number
  number: string
  ddipremium: number
  available?: boolean
}

export class SbfPackage {
  name: string
  seconds: number
  rental: number
  fullPrice: number
  packageId: number
  packageIndex: MinuteBundle
  numberType: NumberType
  users: Users
  type: numberPackageTypes
  umr?: boolean
  whatsApp?: boolean

  isEmpty (): boolean {
    return this.packageId === 0
  }

  isNotEmpty (): boolean {
    return !this.isEmpty()
  }

  constructor (
    name: string,
    seconds: number,
    rental: number,
    fullPrice: number,
    packageID: number,
    packageIndex: number,
    numberType: NumberType,
    users: Users,
    whatsApp?: boolean
  ) {
    this.name = name
    this.seconds = seconds
    this.rental = rental
    this.fullPrice = fullPrice
    this.packageId = packageID
    this.packageIndex = packageIndex
    this.numberType = numberType
    this.users = users
    this.whatsApp = whatsApp ?? false
  }
}

export interface Users {
  qty: number
  monthlyPrice: number
}

export interface Service {
  serviceType: ServiceType
}

interface MatchParams { }

export interface SignupTypeProps extends RouteComponentProps<MatchParams> {
  title: string
  paragraph?: string
  isLoggedIn: boolean
  basket: Basket
  outbound: boolean
  inbound: boolean
  step: SignupStep

  handleBasketClear: () => void

  handleSignupTypeUpdate: (signupTypeProps: SignupTypeProps) => any

  progressToSelectNumberType: () => any
}

export interface SignupState {
  signupTypeProps: SignupTypeProps
}

export interface NumbersForAreaPosition {
  totalRecords: number
  numbers: SbfNumber[]
  capacityReached: boolean
}

// export interface AddToBasketArgs {
//     item: SbfNumber
//     itemType: BasketItemType;
// }

// export interface BasketItem {
//     itemType: BasketItemType;
//     item: SbfNumber
// }

// }

export function isSbfNumber (item: BasketItem): item is SbfNumber {
  return (item as SbfNumber).ddiid !== undefined
}

export function isHardware (item: BasketItem): item is Hardware {
  return (item as Hardware).prodId !== undefined
}

export type BasketItem = SbfNumber | SbfPackage | Hardware

export interface PackagesWrapper {
  packages: Package[]
}

export interface PackageSeconds {
  landlineSeconds: number
  mobileSeconds: number
}

// export interface SelectedPackage {
//     fullPrice: number,
//     name: string,
//     numberType: string,
//     packageId: number | null,
//     packageIndex: number | null,
//     rental: number,
//     seconds: number | null,
//     type: numberPackageTypes,
//     umr: boolean,
//     users: Users
// };

export interface InboundPackageResult {
  landlinePackageId: number
  landlineRental: number
  mobilePackageId: number
  mobileRental: number
  seconds: number
  splitPackageId: number
  splitRental: number
}

export interface InboundPackagesDto {
  inboundPackages: InboundPackageResult[]
}

export interface Package {
  displayOnSignup: boolean
  freeSipTermination: boolean
  inboundSeconds: PackageSeconds
  monthlyPrice: number
  numberType: string
  offerPrice: number
  outboundSeconds: PackageSeconds
  packageId: number
  packageName: string
}

export interface PackageResultDto {
  packages: Package[]
}

export type PackageStub = Package | InboundPackageResult

export enum SupportedCallDirection {
  InboundOnly,
  InboundAndOutbound,
}

export interface SignupProfile {
  marketingSource?: string
  company: string
  name: string
  email: string
  phone: string
}
// export interface LoginDetails {
//     isAuthenticated: boolean;
//     fName: string;
//     email: string;
//     clientId: number;
//     sName: string;
// }

export interface PurchaseTPSProtectionDto {
  EndpointIds: number[]
}

export interface PurchaseResponseDto {
  isSuccess: boolean
  key: string
  msg: string
  ipAddress: string
  name: string
  email: string
}

export interface PurchaseNumberDto {
  success: boolean
  key: string
  ipAddress: string
  paymentRef: string
  providerId?: number | undefined
}

export enum BillingPeriodUnit {
  Week = 2,
  Month = 3,
  Year = 4,
  OneOff = 1,
  NotSet = 0,
  Quarterly = 5,
  None = -1,
  Biannual = 6,
  Yearlyx2 = 7,
}

export interface BasketHardware {
  amount: number
  monthlyPrice: number
  monthlyPayments: boolean
  name: string
  prodId: number
  qty: number
}

export interface UserDetails {
  isAuthenticated: boolean
  fName: string
  sName: string
  email: string
  clientId: number
}

export interface BasketUsers {
  qty: number
  monthlyPrice: number
}

export interface BasketPackage {
  name: string
  packageId: number
  rental: number
  seconds: number
  umr: boolean
  users: BasketUsers
}

export interface BasketNumbers {
  ddiid: number
  ddipremium: number
  number: string
}

export interface BasketInfo {
  globalOffer: boolean
  billingPeriod: BillingPeriodUnit
  hardware: BasketHardware[]
  package: BasketPackage
  numbers: BasketNumbers[]
}

export interface BillingDetailsDto {
  hasAgreement: boolean
  // cardNumber: string;
  // cardExpiry: string;
  // cardCode?: number;
  billingAddress: AddressDto
}

export interface NewNumberPurchaseDto {
  essentials: EssentialInfo
  basket: BasketInfo
  deliveryDetails: AddressDto
  billingDetails: BillingDetailsDto
}

export interface SignupResultDto {
  msg: string
  token: string
  clientId: number
  ipAddress: string
  valid: boolean
  password: string
}

export interface SignupContactsDto {
  landline: string
  mobile: string
}

export interface EssentialInfo {
  name: string
  email: string
  contacts: SignupContactsDto
  optedOut?: boolean
}

export interface OptionalInfo {
  company: string
  marketingSource: string
  referrer: string
  utm_source: string
  utm_campaign: string
  utm_medium: string
  resellerId?: number
  partialId?: number
  WhatsAppPlan?: boolean
  analyticsId: string
  topupCredit: number
  callRecording: boolean
}

export interface NewSignupValidationDto {
  essentials: EssentialInfo
  optionalInfo: OptionalInfo
  billingDetails: BillingDetailsDto | undefined
  isPorting?: boolean
}

export interface SignupValidation {
  validationState: validationStates
  errorMsg: string
}

export interface NumbersByPremiumDto {
  ddiid: number
  number: string
  ddipremium: number
}

export interface NumbersByPremiumDtoWrapper {
  totalRecords: number
  numbers: NumbersByPremiumDto[]
  capacityReached: boolean
}

export interface GoCardlessFlowResultDto {
  redirectUrl: string
  sessionId: string
  goCardlessId: string
}

export interface GoCardlessBillingRequestDataDto {
  Company: string
  Email: string
  Description: string
  Telephone: string
  Address: string
  Town: string
  FirstName: string
  Amount: number
  Mandate: boolean
  LastName: string
  Postcode: string
  ClientId?: number | undefined
  Data?: string | undefined
  SignupKey?: string | undefined
    DebtGuid?: string | undefined
    
}

export interface ReduxState extends DefaultRootState {
  sbfApp: any
  home: any
  myNumbers: any
}

export interface PaymentSuccessDto {
  desc: string
  transStatus: string
  postcode: string
  amount: number | null
  name: string
  address1: string
  address2: string
  town: string
  email: string
  tel: string
  transId: string
  mc_clientid: number | null
  mc_key: string
  futurePayId: string
  mc_ipAddress: string
  mc_paymentProvider: number
  mandateOnly?: boolean | null
  mc_signupKey?: string | undefined
  mc_debtGuid?: string | undefined
}

export interface AddressValidationDto {
  name: validationStates
  add1: validationStates
  add2: validationStates
  add3: validationStates // Town
  pcode: validationStates
}

export interface UserAuthenticationDto {
  token: string
  fName: string
  sName: string
  clientId: number
  email: string
  isBillingRestricted: boolean
}

export interface ProductPuchaseDto {
  qlids: number[]
  billingOption: number
}

export interface StripeIntentDto {
  Amount: number
  Address?: StripeAddressDto | undefined
  Description: string
  ClientId: number | undefined
  Email?: string | undefined
  Name?: string | undefined
  Phone?: string | undefined
  DebtGuid?: string | undefined
  Hardware?: boolean | undefined
  PaymentData?: string | undefined
  SignupData?: string | undefined
}

interface StripeAddressDto {
  Line1: string
  Line2: string
  City: string
  PostCode: string
}

export interface StripeIntentResult {
  success: boolean
  secret: string
}

export interface StripeSecretDto {
  secret: string
  amount: number
}

export interface StripeDeclineReasonDto {
  type: string
  message: string
}

export interface SignupKeyDto {
  key: string
  isNew: boolean
}

export interface NewSendQuoteDto {
  Key: string
}

export interface SignupProgressDto {
  state: string
  clientId?: number
}

export interface MarketingDataDto {
  clickId: string
  source: string
  campaign: string
  medium: string
  referrer: string
}

export interface SignupWithGoogleTokenResponseDto {
  userAuthenticationDto: UserAuthenticationDto;
  isNewClient: boolean;
}

export interface TransactionProductsDto {
  item_name: string
  quantity: number
  price: number
}